import AWS from 'aws-sdk';

// Configure AWS SDK as before
AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION
});

const triggerNucRibbonsEdgeFunction = async (userSessionId, userSub, jsonData) => {
  console.log("triggerNucRibbonsEdgeFunction");

  const blastnCommands = jsonData.reduce((acc, curr, idx, src) => {
    if (idx < src.length - 1) {
      console.log("curr.objectKey", curr.objectKey)
      acc.push({
        user_session_id: userSessionId,
        userSub: userSub,
        query: curr.unique_identifier,
        subject: src[idx + 1].unique_identifier,
        outfmt: "7 qseqid sseqid pident evalue qstart qend sstart send",
        genomeIdQuery: curr.objectKey,
        genomeIdSubject: src[idx + 1].objectKey,
      });
    }
    return acc;
  }, []);
  console.log("blastnCommands", blastnCommands)
  // Initialize AWS Lambda and S3 services
  const lambda = new AWS.Lambda();
  const s3 = new AWS.S3();

  const invokeLambda = async (command) => {
    const params = {
      FunctionName: 'run_blastn_lambda',
      InvocationType: 'RequestResponse',
      LogType: 'None',
      Payload: JSON.stringify(command),
    };

    return lambda.invoke(params).promise();
  };

  try {
    const responses = await Promise.all(blastnCommands.map(invokeLambda));
    console.log("responses", responses)
    // Downloading files from S3 based on the output_file path in each response
    const downloads = await Promise.all(responses.map(async (response, index) => {
      const payload = JSON.parse(response.Payload);
      console.log("payload", payload)
      const s3Params = {
        Bucket: 'phamlite-bucket', // Assuming this is your S3 bucket name
        Key: payload.output_file,
      };
      console.log("s3Params", s3Params)
      const file = await s3.getObject(s3Params).promise();
      return JSON.parse(file.Body.toString());
    }));

    // Combine all JSON objects into one
    const combinedNucJSON = downloads.reduce((acc, curr) => {
      return { ...acc, ...curr };
    }, {});

    return combinedNucJSON; // Add this at the end of the try block

  } catch (error) {
    console.error("Error while invoking lambda functions and downloading files:", error);
    throw error;  // Rethrowing error for caller to handle
  }
};

export default triggerNucRibbonsEdgeFunction;