import { motion, useScroll, useTransform } from 'framer-motion';

const SwirlAnimation = () => {
  const { scrollYProgress } = useScroll();
  const strokeDashOffset = useTransform(scrollYProgress, [0, .5], [1, 0]);

  return (
    <motion.svg
      className="swirl-animation"
      width="100vw"
      height="100vh"
      viewBox="0 0 100vw 100vw"
    >
      <motion.path
        d="m-8,8c12,0 97,46 137,75c40,29 88,107 95,147c7,40 -20,118 -55,139c-35,21 -71,5 -108,-22c-37,-27 -50,-89 12,-116c62,-27 134,37 178,72c44,35 51,105 48,158c-3,53 86,116 150,82c64,-34 119,-84 119,-84"
        fill="none"
        stroke="url(#gradient)"
        strokeWidth="20"
        strokeLinecap="round"
        pathLength="1"
        strokeDasharray="1"
        style={{ strokeDashoffset: strokeDashOffset }}
      />
      <defs>
        <linearGradient id="gradient" x1="0" y1="0" x2="1" y2="1">
          <stop offset="0%" stopColor="#9b85e4" />
          <stop offset="100%" stopColor="#62c4dd" />
        </linearGradient>
      </defs>
    </motion.svg>
  );
};

export default SwirlAnimation;
