import { CalculateGeneCoordinates } from '../utilities/calcCoords';
import { Graphics as PixiGraphics } from 'pixi.js';
import { addHoverEffect } from '../utilities/hoverHandler';

function drawGene({
  gene,
  genomeId,
  genomeIndex,
  scale,
  lineSpacing,
  geneBoxHeight,
  colorMap,
  genomeGroup,
  selectedGenes,
  zoomOnGene,
  zoomInCount,
  boxCoords,
  yScrollOffset,
  setDisplacement,
  displacement,
  clickedGeneFamily,
  isDarkMode,
  viewerWidth,
  arrowSize,
}) {
  // const geneGraphic = new PixiGraphics();
  const wrapMap = true;
  const { points, points2, isBoxSelected, brokenGene } = CalculateGeneCoordinates({
    gene,
    genomeIndex,
    scale,
    lineSpacing,
    geneBoxHeight,
    zoomOnGene,
    zoomInCount,
    genomeGroup,
    boxCoords,
    yScrollOffset,
    setDisplacement,
    displacement,
    wrapMap,
    viewerWidth,
    arrowSize,
  });
  const lineColor = isDarkMode ? 0xffffff : 0x000000;
  const clusterColor = colorMap[`${genomeId}|${gene.name}`];
  const color = clusterColor ? parseInt(clusterColor, 16) : isDarkMode ? 0xa0a0a0 : 0xe1e4e3;
  const geneGraphicContainer = new PixiGraphics(); // Use a container to hold both parts of the gene

  // Message for GPT4: if points2 is not empty, draw each points as a seperate polygon in a container

  // geneGraphic.poly(points);
  // geneGraphic.eventMode = 'dynamic';
  // geneGraphic.fill(brokenGene ? 0xFFC0CB : color);
  // geneGraphic.stroke({ color: lineColor, width: 1 });
  // geneGraphic.interactive = true;
  // Draw the first part
  const geneGraphic1 = new PixiGraphics();
  geneGraphic1.poly(points);
  geneGraphic1.eventMode = 'dynamic';
  geneGraphic1.fill(brokenGene ? 0xFFC0CB : color);
  geneGraphic1.stroke({ color: lineColor, width: 1 });
  addHoverEffect(geneGraphic1, gene, genomeGroup, isDarkMode); // apply hover effects
  geneGraphicContainer.addChild(geneGraphic1); // Add the first polygon to the container

  // Check if there is a second part to draw
  if (points2.length > 0) {
    const geneGraphic2 = new PixiGraphics();
    geneGraphic2.poly(points2);
    geneGraphic2.eventMode = 'dynamic';
    geneGraphic2.fill(brokenGene ? 0xFFC0CB : color);
    geneGraphic2.stroke({ color: lineColor, width: 1 });
    addHoverEffect(geneGraphic2, gene, genomeGroup, isDarkMode); // apply hover effects to the second part as well
    geneGraphicContainer.addChild(geneGraphic2); // Add the second polygon to the container
  }

//   if (clickedGeneFamily !== null) {
//     console.log('clickedGeneFamily_here', clickedGeneFamily);
//     geneGraphic.alpha = `${genomeId}|${gene.name}` === clickedGeneFamily ? 1 : 0.4;
//   } else {
//     geneGraphic.alpha = selectedGenes.some((selectedGene) => selectedGene.name === gene.name) || selectedGenes.length === 0 ? 1 : 0.4;
//   }

  // addHoverEffect(geneGraphic, gene, genomeGroup, isDarkMode);

  return { geneGraphicContainer, isBoxSelected };
}

export { drawGene };