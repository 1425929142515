import React from 'react';

const ViewerSettings = ({ setClickedViewerSettings, hideGrid, setHideGrid, isDarkMode, setIsDarkMode }) => {
  // Managing state for hide grid and dark mode

  const handleOuterClick = (e) => {
    if (e.target === e.currentTarget) {
      setClickedViewerSettings(false);
    }
  };

  // Handler for hide grid toggle
  const toggleHideGrid = () => {
    setHideGrid(!hideGrid);
  };

  // Handler for dark mode toggle
  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(128, 128, 128, 0.5)',
        zIndex: 200000000000, // Ensuring it's above everything else
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
      onClick={handleOuterClick}
    >
      <div
        style={{
          backgroundColor: '#808080',
          padding: '20px',
          width: '50%',
          height: '30%',
          borderRadius: '16px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
        }}
        onClick={(e) => e.stopPropagation()}
      >
        {/* Toggle for hide grid */}
        <div style={{ marginBottom: '20px' }}>
          <label>
            <input
              type="checkbox"
              checked={hideGrid}
              onChange={toggleHideGrid}
            />{' '}
            Hide Grid
          </label>
        </div>
        
        {/* Toggle for dark mode */}
        <div>
          <label>
            <input
              type="checkbox"
              checked={isDarkMode}
              onChange={toggleDarkMode}
            />{' '}
            Dark Mode
          </label>
        </div>
      </div>
    </div>
  );
};

export default ViewerSettings;