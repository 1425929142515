import { motion } from 'framer-motion';

const LoadingDots = () => {
    const dotVariants = {
      dark: { backgroundColor: '#333' },
      light: { backgroundColor: '#ccc' },
    };
  
    const dotTransition = {
      duration: 0.5,
      yoyo: Infinity,
      ease: 'easeInOut',
    };
  
    return (
      <motion.div 
      style={{ display: 'flex', alignItems: 'center' }}
      initial={{ y: -10 }}
      animate={{ y: 0 }}
      >
        <motion.div
          style={{
            width: '8px',
            height: '8px',
            borderRadius: '50%',
            margin: '0 4px',
          }}
          variants={dotVariants}
          initial="dark"
          animate="light"
          transition={{ ...dotTransition, delay: 0 }}
        />
        <motion.div
          style={{
            width: '8px',
            height: '8px',
            borderRadius: '50%',
            margin: '0 4px',
          }}
          variants={dotVariants}
          initial="dark"
          animate="light"
          transition={{ ...dotTransition, delay: 0.2 }}
        />
        <motion.div
          style={{
            width: '8px',
            height: '8px',
            borderRadius: '50%',
            margin: '0 4px',
          }}
          variants={dotVariants}
          initial="dark"
          animate="light"
          transition={{ ...dotTransition, delay: 0.4 }}
        />
      </motion.div>
    );
  };
  
  export default LoadingDots;