import React from 'react';
import { motion, useAnimation } from 'framer-motion'; // Import useAnimation

const SlidingHandle = ({ onDrag, side }) => {
  const controls = useAnimation(); // Controls to start animation
  
  // Styles for the outer, wider div
  const outerHandleStyles = {
    minWidth: '10px',
    width: '10px',
    maxWidth: '10px',
    height: '100%',
    cursor: 'col-resize',
    display: 'flex',
    justifyContent: side=== "right" ? 'left': 'right',
    userSelect: 'none',
    borderRight: side==="right" ? '' : '1px solid rgba(39, 41, 56, 1)',
    // paddingRight: '50px',
    zIndex: 1000,
    backgroundColor: "var(--primary-color-dark-mode)"
  };

  // Function to trigger animations
  const handleHoverStart = () => controls.start("hover");
  const handleHoverEnd = () => controls.start("initial");

  return (
    <motion.div
      style={outerHandleStyles}
      onMouseDown={onDrag}
      onHoverStart={handleHoverStart} // Trigger when hover starts on the DIV
      onHoverEnd={handleHoverEnd} // Trigger when hover ends on the DIV
    >
      <motion.div
        animate={controls} // Use animation controls
        initial="initial" // Starting state as initial
        variants={{
          initial: {
            width: '0px'
          },
          hover: {
            width: '100%', // Expand to 100% of the parent's width on hover
            transition: {
              duration: 0.1,
              ease: 'easeInOut',
            }
          },
        }}
        style={{
          height: '100%',
          backgroundColor: 'rgba(39, 41, 56, 1)',
        }}
      />
    </motion.div>
  );
};

export default SlidingHandle;