import React from 'react';
import { motion } from 'framer-motion';
import LoadingDots from '../components/LoadingDots'
import { Settings, Share } from 'lucide-react';

const fontDefocus = "rgb(133, 134, 153)";

export const ViewButton = ({ onClick, active, icon, name }) => (
    <motion.div
      className="view-button"
      onClick={() => onClick(name)}
      initial={{ backgroundColor: "hsla(0, 0%, 0%, 0)" }}
      animate={{ backgroundColor: active ? "hsla(0, 0%, 100%, 0.14)" : "hsla(0, 0%, 0%, 0)" }}
      whileHover={{ backgroundColor: active ? "hsla(0, 0%, 100%, 0.14)" : "hsla(236, 16.48%, 17.84%, 1)" }}
      transition={{
        ease: "linear",
        duration: 0.5,
      }}

    >
      {icon}
    </motion.div>
  );
  export const FunctionButton = ({ onClick, loading, result, icon, text, resultIcon, resultText }) => (
    <motion.div
      className="function-button-div"
      onClick={result ? null : onClick}
      style={{
        paddingLeft: result ? 3 : 10,
        paddingRight: result ? 3 : 10,
        cursor: result ? "default" : "pointer",
      }}
      whileHover={result ? {} : { backgroundColor: "rgba(38, 39, 53, 1)" }}
    >
      {loading ? (
        <LoadingDots />
      ) : result !== null ? (
        <div className="seq-num" style={{ height: "100%", width: "100%" }}>
          {resultIcon}
          <span style={{ fontSize: 12 }}>{resultText}</span>
        </div>
      ) : (
        <>
          {icon}
          <span style={{ color: fontDefocus, fontSize: 16 }}>{text}</span>
        </>
      )}
    </motion.div>
  );
  
  export const SettingsButton = ({ onClick }) => (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {/* <span style={{ color: fontDefocus, fontSize: 12 }}>.</span> */}
      <motion.div className="function-button-div" style={{ width: "50px" }} onClick={onClick}>
        <Settings size={18} color={fontDefocus} />
      </motion.div>
    </div>
  );
  
  export const SharingButton = ({ onClick }) => (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {/* <span style={{ color: fontDefocus, fontSize: 12 }}> .</span> */}
      <motion.div className="function-button-div" style={{ width: "50px" }} onClick={onClick}>
        <Share size={18} color={fontDefocus} />
      </motion.div>
    </div>
  );