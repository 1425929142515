import React, { useState, useEffect, useContext, useRef } from 'react';
import '../style/InfoBox.css';
// import FastaInput from './FastaInput';
// import GeneInfoTable from './GeneInfoTable';
import GeneInfoCard from './GeneInfoCard';
import { BoxCoordsContext } from '../utilities/BoxCoordsContext';
import { motion, useAnimate, AnimatePresence} from 'framer-motion';
import PDBViewer from './PDBViewer';
import GridLoader from "react-spinners/GridLoader";
import ProteinFamilyAlignment from '../components/ProteinFamilyAlignment';
import '../style/ButtonComponent.css';
import  ProteinAlignmentViewer  from './FastaAlignmentViewer';
import ComputePhylogeny from './ComputePhylogeny';
import TreeViewer from './TreeViewer';

function InfoBox({ selectedGenes, 
                   userSessionId, 
                   boxSelectCoordinates, 
                   setDisableDrag, 
                   disableDrag,
                   addNotification, 
                   setClickedGeneFamily, 
                   alnTSV, 
                   setPdbData,
                   pdbData,
                   setIsDraggable,
                   isDraggable,
                   setSelectedGenes,
                   visibleGenomeJSON,
                   setDisableScroll,
                   setScrollX,
                   viewerWidth,
                   scrollX,
                   setInfoPanelOpen,
                   setClusterGroupClickedInfo,
                   setProteinAlignment,
                   clusterGroupClickedInfo,
                   repCSSColor,
                   setRepCSSColor,
                   setFunctionClicked,
                   functionClicked,
                   setDisableClick,
                   disableClick,
                   }) {
    const [geneSelected, setGeneSelected] = useState(selectedGenes.length > 0);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [midOfSelection, setMidOfSelection] = useState(null);
    const boxCoords = useContext(BoxCoordsContext);
    const [scope, animate] = useAnimate()
    const [foldLoading, setFoldLoading] = useState(false);  // State to control arrow direction

    useEffect(() => {
        // setPdbData(''); // clear pdb data when new gene is selected
        // setClusterGroupClickedInfo([]); // clear cluster group info when new gene is selected
        setProteinAlignment(null); // clear protein alignment when new gene is selected

        setGeneSelected(selectedGenes.length > 0);
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        if (selectedGenes.length > 0) {
            const mid = boxCoords.left + (boxCoords.width / 2) + scrollX;
            setMidOfSelection(mid);

        } 
        return () => window.removeEventListener('resize', handleResize);
    }, [selectedGenes, boxCoords, scrollX]);

    useEffect(() => {
        if (clusterGroupClickedInfo && clusterGroupClickedInfo.length > 1) {
            setInfoPanelOpen(true);
        } else {
            setInfoPanelOpen(false);
        }
    }, [clusterGroupClickedInfo])


    const handleMouseEnter = () => {
        setDisableClick(true); // Disable drag when mouse enters the div
        setDisableScroll(true);
        setIsDraggable(true);
    };
    const handleMouseLeave = () => {
        setDisableClick(false); // Re-enable drag when mouse leaves the div
        setDisableScroll(false);
        setIsDraggable(false);
    };
    
    // const headers = ["name", "start", "stop", "sequence", "cluster_id", "confidence"];
    const [position, setPosition] = useState({ x: 0, y: 0 });
    
    useEffect(() => {
        // Reset the position whenever selectedGenes changes
        setPosition({ x: 0, y: 0 });
    }, [selectedGenes]); // Add selectedGenes as a dependency

    const generateNCBILink = () => {
        const seq = selectedGenes[0].sequence;
        // Trim whitespace and remove newlines if your input includes FASTA format headers or newlines.
        const cleanSeq = seq.replace(/\s+|\n+/g, '');
        return `https://blast.ncbi.nlm.nih.gov/Blast.cgi?PROGRAM=blastp&PAGE_TYPE=BlastSearch&BLAST_SPEC=&LINK_LOC=blasttab&QUERY=${cleanSeq}`;
    };

    const handleBlastNcbiClick = () => {
        // Implement the functionality
        const link = generateNCBILink()
        window.open(link, '_blank'); // '_blank' specifies that the link should open in a new tab or window
    };
    // Function to determine if the Fold button should be shown
    const shouldShowFoldButton = () => {
        return selectedGenes.length > 0 && selectedGenes[0].sequence.length < 400;
    };
    const handleFoldClick = async () => {
        setFoldLoading(true);
        setInfoPanelOpen(true);
        setPdbData(''); // clear pdb data when new gene is selected
        // setClusterGroupClickedInfo([])
        const url = "https://api.esmatlas.com/foldSequence/v1/pdb/";
        const seq = selectedGenes[0].sequence;
        const cleanSeq = seq.replace(/[\s\n*]+/g, '');
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'text/plain',
                },
                body: cleanSeq
            });
    
            if (!response.ok) {
                throw new Error(`HTTP Error: ${response.status}`);
            }
    
            // Check the content type of the response
            const contentType = response.headers.get("content-type");
            if (contentType && contentType.indexOf("application/json") !== -1) {
                const jsonResponse = await response.json();
            } else {
                const textResponse = await response.text();
                setPdbData(textResponse);
            }
            setFoldLoading(false);
        } catch (error) {
            console.error("Request failed", error);
        }
    };
    
    const functionButtons = [
        { id: 1, label: 'BLAST NCBI', onClick: handleBlastNcbiClick },
        ...(shouldShowFoldButton() ? [{ id: 2, label: 'Fold', onClick: handleFoldClick }] : [])
    ];

    const toggleAnimation = async () => {
        console.log("CLICKED");
        
        // Immediately set the state
        setFunctionClicked((prev) => !prev);
      
        // Instead of directly using `functionClicked` in your animation
        // (which may not have the updated value yet),
        // you calculate the desired state based on the action (which, in this case, is toggling).
        // This ensures the animation uses the intended state.
        
        // Wait for the next tick to ensure `functionClicked` state is updated
        // Though not ideal, setTimeout with 0 delay is a common trick to defer an action until after the pending state updates are processed.
        setTimeout(() => {
          const targetBackground = !functionClicked ? 'var(--highlight-color-2-dark-mode)' : 'rgba(0,0,0,0)';
          const targetBorder = !functionClicked ? '1px solid var(--text-color-dark-mode)' : '0px solid transparent';
          const targetRotate = !functionClicked ? 45 : 0;
      
          animate("#background", { backgroundColor: targetBackground, border: targetBorder }, { duration: .222, ease: "easeInOut"});
          animate("#plus", { rotate: targetRotate }, { duration: .222, ease: "easeInOut"});
        }, 0);
      }
    const totalButtonsToShow = functionClicked ? functionButtons.length : 0; 

      const itemVariants = {
        hidden: { x: -10, opacity: 0 },
        visible: { x: 0, opacity: 1 },
        exit: { x: 10, opacity: 0 },
      };

    const handleDownload = () => {
        // Create a Blob from the PDB data
        const blob = new Blob([pdbData], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);
        const name = selectedGenes[0].name;
        // Create a temporary link element
        const link = document.createElement('a');
        link.href = url;
        link.download = `${name}.pdb`; // Filename for the download
        
        // Append the link to the document, trigger the download, and remove the link
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        
        // Revoke the blob URL to free up resources
        URL.revokeObjectURL(url);
    };
    const handleMemberRowClick = (geneId) => {
        // const genomeId = geneId.split('|')[0];
        // const geneName = geneId.split('|').slice(1).join('|')
        setClickedGeneFamily(geneId);
    };

    // Handler to enable dragging
    const enableDrag = () => setIsDraggable(true);

    function AlignmentSVG({ cstart, cend, mstart, mend }) {
        // Normalize lengths to a 40-pixel scale.
        const scaleLength = 40;
      
        // Calculate how many units each pixel represents.
        const unitsPerPixel = (cend - cstart) / scaleLength;
      
        // Compute the start and end points of the member line relative to the centroid line.
        const mLineStart = Math.max(0, cstart / unitsPerPixel);
        const mLineEnd = Math.min(scaleLength, ( cend / unitsPerPixel) + mLineStart);
      
        return (
          <svg width="40" height="10">
            {/* Centroid line (top line) */}
            <line x1="0" y1="1" x2="40" y2="1" stroke="black" strokeWidth="2" />
      
            {/* Member line (bottom line), normalized to the centroid line length */}
            <line x1={mLineStart} y1="6" x2={mLineEnd} y2="6" stroke={repCSSColor} strokeWidth="2" />
          </svg>
        );
      }
    // Function to calculate the amino acid length of a gene given its ID
    const calculateAALength = (mseqid, visibleGenomeJSON) => {
        for (const genome of visibleGenomeJSON) { // Loop through each genome object
        for (const gene of genome.genes) { // Loop through genes of each genome
            console.log(gene.name)
            console.log(mseqid.split('|').slice(1))
            if (gene.name === mseqid.split('|').slice(1).join('|')) {
            // If the gene's name matches mseqid,
            // calculate the AA length: (stop - start + 1) / 3
            const aaLength = Math.abs(gene.stop - gene.start + 1) / 3;
            // Assuming inclusive positions and that each set of 3 nucleotides encodes an AA
            return aaLength;
            }
        }
        }
    
        return 0; // Return 0 if no matching gene ID is found
    };

    return (
        <motion.div 
            key={selectedGenes} // Change the key to force re-render
            className="info-boxes-container"
        >
            <AnimatePresence>
            {geneSelected && (
            <motion.div
                ref={scope}
                onHoverStart={handleMouseEnter} // Triggered when mouse enters the div
                onHoverEnd={handleMouseLeave} // Triggered when mouse leaves the div
                className='info-pop-up'
                animate={{ x: midOfSelection, y: boxCoords.top + 50, opacity: 1 }} // Move to the middle of the selection
                initial={{ x: midOfSelection, y: boxCoords.top + 60, opacity: 0 }} // Set initial position off-screen
                // exit={{ x: midOfSelection, y: boxCoords.top + 100, opacity: 0 }} // Move off-screen on exit
                transition={{ duration: 0.22, ease: 'easeOut' }} // Customize animation
                // drag={isDraggable} // Control drag based on state
                // drag={true} 
                // drag based on setDisableDrag state
                drag={isDraggable}
                dragMomentum={false} // Disables momentum/inertia effect
                onDragEnd={(event, info) => {
                    setPosition({
                        x: position.x + info.offset.x,
                        y: position.y + info.offset.y,
                    });
                }}
            >   
                <div className='both-sides'>
                    <div className="left-side">
                        <GeneInfoCard 
                            selectedGenes={selectedGenes} 
                            addNotification={addNotification} 
                            setClickedGeneFamily={setClickedGeneFamily}
                            alnTSV={alnTSV}
                            pdbData={pdbData}
                            disableDrag={disableDrag}
                            enableDrag={enableDrag}
                            setClusterGroupClickedInfo={setClusterGroupClickedInfo}
                            setRepCSSColor={setRepCSSColor}
                            setScrollX={setScrollX}
                            viewerWidth={viewerWidth}
                            setInfoPanelOpen={setInfoPanelOpen}
                        />
                        {/* FUNCTIONS BUTTON PANEL*/}
                        <div 
                            className="button-panel"
                            id="background"
                        >
                        <AnimatePresence>
                            <motion.div
                            className="function-grid-button-anchor"
                            whileHover={{ scale: 1.05 }}
                            onClick={toggleAnimation}
                            >
                            <div className="function-button-container">
                                <div id="plus">
                                    <svg width="20" height="20" viewBox="0 0 20 20">
                                        <circle cx="10" cy="10" r="10" fill="var(--text-color-dark-mode)"/>
                                        <path d="M 5,10 H 15 M 10,5 V 15" stroke="black" strokeWidth="1" />
                                    </svg>
                                </div>
                                <span className="functions">Functions</span>
                            </div>

                            </motion.div>
                            {functionButtons.slice(0, totalButtonsToShow).map((button) => (
                            <motion.div
                                key={button.id}
                                variants={itemVariants} // Use the same variants for consistency
                                className="function-grid-button"
                                whileHover={{ scale: 1.05 }}
                                onClick={button.onClick} // Assign the click handler here
                                >
                                {button.label}
                                
                            </motion.div>
                            ))}
                        </AnimatePresence>
                    </div>
                </div>
                </div>
            </motion.div>
            )}
            </AnimatePresence>
        </motion.div>
    );
}

export default InfoBox;
