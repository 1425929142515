// drawGenomeGroups.js
import { Container as PixiContainer, Graphics } from 'pixi.js';

import { drawGene } from './DrawGene';
import { drawGenomeLines } from './drawGenomeLine';
import { addGenomeName } from './GenomeNameHandler';
// import { drawGenomeSequence } from './DrawGenomeSequence';
import { drawRibbons } from './DrawRibbons';
import { createColorMap, createMemberToRepMap, countMembersPerRep } from './colorMapping'; // Import the function
import { signal } from "@preact/signals-react";

// Signal to track whether the Shift key is pressed
const shiftPressed = signal(false);
  
const colorMapSignal = signal(null);
const repMemberCountMapSignal = signal(null);
const memberToRepMapSignal = signal(null);

function drawGenomeGroups({genomeJSON, 
                          nucJSON, 
                          sliderValues, 
                          scale, 
                          boxCoords,
                          selectedGenes, 
                          viewerWidth,
                          viewerHeight, 
                          setSelectedGenes, 
                          zoomOnGene, 
                          zoomInCount,
                          yScrollOffset,
                          setDisplacement,
                          displacement,
                          clickedGeneFamily,
                          isDarkMode,
                          clusterData,
                          setClusterData,
                          setNucJSON,
                          scrollX,
                          setIndexOfSelectedGenome,
                          indexOfSelectedGenome,
                          wrapGenome,
                          setNumberOfClusters,
                          }) {
    const g = new PixiContainer();
    const lineThickness = sliderValues.slider1 || 1;
    const verticalSpacing = sliderValues.slider2 || 40;
    const tickInterval = sliderValues.slider4 || 10;
    let selectedGenesTemp = [];
    const lineSpacing = 60;
    const geneBoxHeight = 15;
    const genomeLineOffset = 2;
    // 25 pixels higher than the gene box height
    const genomeNameOffset = -(geneBoxHeight + 25);
    const topPadding = Math.abs(geneBoxHeight + genomeNameOffset); 
    const arrowSize = 5;
    const genomeGeneBounds = {};
    const leftPadding = 10;
    // add left padding to the left of boxCoords
    // boxCoords.left = boxCoords.left - leftPadding;

    let colorMap;

    if (clusterData) {
        colorMap = createColorMap(clusterData);
        colorMapSignal.value = colorMap;
        const memberToRepMap = createMemberToRepMap(clusterData);
        const { repMemberCount, repsWithMultipleMembers } = countMembersPerRep(memberToRepMap);
        setNumberOfClusters(repsWithMultipleMembers);
        repMemberCountMapSignal.value = repMemberCount;
        memberToRepMapSignal.value = memberToRepMap;
    } else {
        colorMap = {};
    }
    const bottomLineOffset = 10; // Offset for the bottom genome line
    // Create a map of genome ID to its index
    genomeJSON.forEach((genome, genomeIndex) => {
            const y = topPadding + (genomeIndex * verticalSpacing);
            // Compute genomeGeneBounds after the gene loop
            genomeGeneBounds[genome.unique_identifier] = {
                top: y + (genomeIndex * lineSpacing),
                bottom: (y + (genomeIndex * lineSpacing)) + geneBoxHeight
            };
        });
   
    let ribbonsContainer = null; // Declare ribbonsContainer and initialize it to null

    if (nucJSON) {
        const genomeIndexMap = genomeJSON.reduce((map, genome, index) => {
          map[genome.unique_identifier] = index;
          return map;
        }, {});
        
        ribbonsContainer = drawRibbons({nucJSON: nucJSON, 
                                              genomeIndexMap: genomeIndexMap, 
                                              scale: scale,
                                              genomeGeneBounds: genomeGeneBounds, 
                                              displacement: displacement,
                                              });
        ribbonsContainer.x = leftPadding;
        g.addChild(ribbonsContainer);
      }
    // Listen for keydown and keyup to update `shiftPressed`
    document.addEventListener('keydown', event => {
        if (event.key === 'Shift') {
        shiftPressed.value = true;
        // Update the overlay interaction state
        updateOverlayInteraction();
        }
    });
    
    document.addEventListener('keyup', event => {
        if (event.key === 'Shift') {
            shiftPressed.value = false;
            // Update the overlay interaction state
            updateOverlayInteraction();
        }
    });
    console.log("shiftPressed", shiftPressed.value)
    function updateOverlayInteraction() {
        g.children.forEach(child => {
          if(child instanceof Graphics) {
            child.interactive = shiftPressed.value;
            child.alpha = shiftPressed.value ? 0 : 0; // Optionally make the overlay slightly visible when active
          }
        });
      }
      let selectedGenomeGroup = null;
    
    genomeJSON.forEach((genome, genomeIndex) => {
        const genomeGroupParent = new PixiContainer();
        genomeGroupParent.interactive = true;

        const genomeGroup = new PixiContainer();
        genomeGroup.interactive = true;
        genomeGroup.cullableChildren = false;
        // Adjust Y-coordinate for top padding and consistent spacing
        genomeGroup.y = topPadding + (genomeIndex * verticalSpacing);
        genomeGroup.x = leftPadding;

        genome.genes.forEach(gene => {
            const { geneGraphicContainer, isBoxSelected,  } = drawGene({
                    gene: gene, 
                    genomeId: genome.unique_identifier,
                    genomeIndex: genomeIndex, 
                    scale: scale, 
                    lineSpacing: lineSpacing, 
                    geneBoxHeight: geneBoxHeight, 
                    colorMap: colorMap, 
                    genomeGroup: genomeGroup, 
                    lineThickness: lineThickness, 
                    selectedGenes: selectedGenes, 
                    zoomOnGene: zoomOnGene, 
                    zoomInCount: zoomInCount,
                    boxCoords: boxCoords,
                    yScrollOffset: yScrollOffset,
                    setDisplacement: setDisplacement,
                    displacement: displacement,
                    clickedGeneFamily: clickedGeneFamily,
                    isDarkMode: isDarkMode,
                    viewerWidth: viewerWidth,
                    arrowSize: arrowSize});

            if (isBoxSelected) {
                // Enhance the gene object with genomeId before pushing
                const geneWithGenomeId = { ...gene, genomeId: genome.unique_identifier };
                selectedGenesTemp.push(geneWithGenomeId); // Add the enhanced gene object to the temporary array
                setIndexOfSelectedGenome(genomeIndex)
            }

            if (indexOfSelectedGenome !== null && genomeIndex !== indexOfSelectedGenome) {
                geneGraphicContainer.alpha = 1;
            } 
            geneGraphicContainer.cursor = 'pointer';
            geneGraphicContainer.on('pointerdown', (event) => {
                console.log("clicked");
                console.log("event", event.x);
                const geneWithGenomeId = { ...gene, genomeId: genome.unique_identifier };
                console.log("geneWithGenomeId", geneWithGenomeId);
                setSelectedGenes([geneWithGenomeId]);
          
                // Reset alpha of previously selected genomeGroup
                if (selectedGenomeGroup && selectedGenomeGroup !== genomeGroup) {
                  selectedGenomeGroup.children.forEach(child => {
                    child.alpha = 1;
                  });
                }
          
                // Set alpha of current genomeGroup except the clicked gene
                genomeGroup.children.forEach(child => {
                  if (child !== geneGraphicContainer ) {
                    child.alpha = 0.6;
                  }
                });
          
                selectedGenomeGroup = genomeGroup;
              });
            // append to genome container
            genomeGroup.addChild(geneGraphicContainer);
        });
        

        // Call the function to draw lines and ticks
        const genomeLengthInPixels = drawGenomeLines(
            {
            genome: genome, 
            genomeIndex: genomeIndex, 
            genomeGroup: genomeGroup, 
            scale: scale, 
            lineSpacing: lineSpacing, 
            bottomLineOffset: bottomLineOffset, 
            geneBoxHeight: geneBoxHeight, 
            genomeLineOffset: genomeLineOffset,
            isDarkMode: isDarkMode,
            viewerWidth: viewerWidth,
            displacement: displacement,
            wrapGenome: wrapGenome});
        
        // Use the addGenomeName function
        addGenomeName(genomeGroup,
                    genome.genome_name.header,
                    genomeIndex,
                    lineSpacing,
                    geneBoxHeight,
                    genomeNameOffset,
                    isDarkMode,
                    scrollX);
            
        // drawGenomeSequence(genome, genomeIndex, genomeGroup, scale, lineSpacing, tickInterval, geneBoxHeight, genomeLineOffset, genomeLengthInPixels, viewerWidth);
        genomeGroupParent.addChild(genomeGroup);
        // Set the width and height of the parent container
        genomeGroupParent.width = viewerWidth;
        genomeGroupParent.height = verticalSpacing;

        g.addChild(genomeGroup);
        const overlayGraphic = new Graphics();
        overlayGraphic.eventMode = "dynamic";
        const yPos = genomeIndex*100;
        const additionalPadding = 20;
        const overlayHeight = verticalSpacing+(additionalPadding*2);
        overlayGraphic.rect(0, yPos-additionalPadding, viewerWidth, overlayHeight);
        // overlayGraphic.stroke({color: "black", width: 5});
        overlayGraphic.fill({ alpha: 0});
        overlayGraphic.on('wheel', (event) => {

            const deltaX = event.deltaX;
            const deltaY = event.deltaY;
            console.log("genomeIndex", genomeIndex)
            if (Math.abs(deltaX) > Math.abs(deltaY)) {
                genomeGroup.x += deltaX;
                if (nucJSON) {
                    // Working with genomeIndex to select the right children
                    let selectedChildren = [];
                    if(genomeIndex === 0) {
                        // Only select the first child if genomeIndex is 0
                        selectedChildren.push(ribbonsContainer.children[0]);
                    } else {
                        // Select children based on the genomeIndex pattern
                        // what happens if nucJSON is missing data for a alignment (no hits)
                        for (let i = genomeIndex - 1; i <= genomeIndex  && i < ribbonsContainer.children.length; i++) {
                            selectedChildren.push(ribbonsContainer.children[i]);
                        }
                    }
            
                    // Now iterating over selected children of the group
                    selectedChildren.forEach((group, index) => {
                        if (group) {
                            group.children.forEach((ribbonGraphic) => {
                                const intersecting = ribbonGraphic.intersecting; // Retrieve the original points stored
                                
                                if (ribbonGraphic.data && !intersecting) {
                                    const originalPoints = ribbonGraphic.data; // Retrieve the original points stored

                                    // Decide which points to adjust based on genomeIndex and child index
                                    if (genomeIndex === 0) {
                                        originalPoints[0].x += deltaX;
                                        originalPoints[3].x += deltaX;
                                    } else {
                                        if (index === 0) { // First selected child based on genomeIndex
                                            originalPoints[1].x += deltaX;
                                            originalPoints[2].x += deltaX;
                                        } else { // Second selected child
                                            originalPoints[0].x += deltaX;
                                            originalPoints[3].x += deltaX;
                                        }
                                    }
            
                                    // Clear the existing graphic
                                    ribbonGraphic.clear();
                                            
                                    // Draw the graphic with updated points
                                    ribbonGraphic.moveTo(originalPoints[0].x, originalPoints[0].y);
                                    originalPoints.forEach(p => ribbonGraphic.lineTo(p.x, p.y));
                                    
                                    ribbonGraphic.fill({color: 0x008000, alpha: 0.5}); // Example fill color with alpha
                                    ribbonGraphic.lineStyle({width: 2, color: 0xFEEB77 }); 
                                } else if (ribbonGraphic.data && intersecting) {
                                    const originalPoints = ribbonGraphic.data; // Retrieve the original points stored
                                    let midX = ribbonGraphic.midX; // Retrieve the original points stored
                                    // Decide which points to adjust based on genomeIndex and child index
                                    if (genomeIndex === 0) {
                                        originalPoints[0].x += deltaX;
                                        originalPoints[3].x += deltaX;
                                        midX += deltaX / 2; // that was hard to figure out
                                    } else {
                                        if (index === 0) { // First selected child based on genomeIndex basically the top gorup
                                            originalPoints[1].x += deltaX;
                                            originalPoints[2].x += deltaX;
                                            midX += deltaX / 2
                                        } else { // Second selected child, the bottom group
                                            originalPoints[0].x += deltaX;
                                            originalPoints[3].x += deltaX;
                                            midX += deltaX / 2
                                        }
                                    }
                                    ribbonGraphic.midX = midX;
                                    ribbonGraphic.clear();

                                    ribbonGraphic.moveTo(originalPoints[0].x, originalPoints[0].y);
                                    ribbonGraphic.lineTo(ribbonGraphic.midX, ribbonGraphic.midY);
                                    ribbonGraphic.lineTo(originalPoints[3].x, originalPoints[3].y); 
                                    ribbonGraphic.lineTo(originalPoints[0].x, originalPoints[0].y); 
                                    
                                    ribbonGraphic.moveTo(originalPoints[1].x, originalPoints[1].y);
                                    ribbonGraphic.lineTo(ribbonGraphic.midX, ribbonGraphic.midY);
                                    ribbonGraphic.lineTo(originalPoints[2].x, originalPoints[2].y);
                                    ribbonGraphic.lineTo(originalPoints[1].x, originalPoints[1].y);

                                    ribbonGraphic.fill({color: 0x008000, alpha: 0.5}); // Example fill color with alpha
                                    ribbonGraphic.lineStyle({width: 2, color: 0xFEEB77 }); 

                                }
                            });
                        }
                    });
                }
            }
        });
        g.addChild(overlayGraphic);

    });

        // Check if selectedGenesTemp is different from selectedGenes before updating
        if (JSON.stringify(selectedGenes) !== JSON.stringify(selectedGenesTemp)) {
            setSelectedGenes(selectedGenesTemp); 
        }


    return g;
}
export { drawGenomeGroups, colorMapSignal, repMemberCountMapSignal, memberToRepMapSignal};
