import { useState } from 'react';
import NotificationsComponent from '../components/NotificationsComponent.js';

let notificationIdCounter = 0;

export const useNotificationManager = () => {
    const [notifications, setNotifications] = useState([]);

    const addNotification = (message, duration = 5000) => { // Default duration is 5000ms (5 seconds)
        const id = notificationIdCounter++;
        const notificationComponent = <NotificationsComponent key={id} message={message} />;
        setNotifications(prevNotifications => [...prevNotifications, { id, component: notificationComponent }]);

        if (duration > 0) {
            setTimeout(() => {
                removeNotification(id);
            }, duration);
        }
    };

    const removeNotification = (id) => {
        setNotifications(prevNotifications => prevNotifications.filter(notification => notification.id !== id));
    };

    return { notifications, addNotification, removeNotification };
};
