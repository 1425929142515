function parseNewick(newick) {
    let root = { name: "root", children: [] };
    let stack = [root];
    let names = newick.split(/\s*(;|\(|\)|,)\s*/);
    for (let i = 0; i < names.length; i++) {
      let name = names[i];
      switch (name) {
        case "(":
          // Start a new branch
          let subtree = { name: "internal", children: [] };
          stack[stack.length - 1].children.push(subtree);
          stack.push(subtree);
          break;
        case ",":
          // Another branch
          break;
        case ")":
          // End current branch
          stack.pop();
          break;
        case ";":
          // End of tree
          break;
        default:
          // Leaf node
          let leaf = { name };
          stack[stack.length - 1].children.push(leaf);
      }
    }
    return root;
  }

  export { parseNewick };
