import React, { useEffect } from 'react';
import { Auth } from '@supabase/auth-ui-react';
import { supabase } from '../utilities/supabaseClient'; // Adjust the path as necessary
import { useNavigate } from 'react-router-dom';
import { SocialLayout, ThemeSupa, ViewType } from '@supabase/auth-ui-shared';
import { useAuth } from '../utilities/AuthContext';
const SignUp = () => {
  
  const navigate = useNavigate();
  const { session } = useAuth(); // returns watched auth session form supabase

  useEffect(() => {
    supabase.auth.onAuthStateChange((event, session) => {
      if (event === 'SIGNED_IN' && session) {
        // Redirect to /dashboard upon successful sign-in
        console.log("event: ", event)
        console.log("session: ", session)
        navigate('/dashboard');
      }
    });

  }, [navigate]);
  useEffect(() => {
    if (session) {
      navigate('/dashboard'); // url can be configured by params or location state
    }
  }, [session, navigate]);
  return (
    <div style={{ display: "flex", width: "100%", height: "100vh", justifyContent: "center", alignItems: "center" }}>
      <div style={{
          display: "flex", 
          flexDirection: "column",
          justifyContent: "center",
          width: "30vw",
          backgroundColor: "white",
          padding: "20px",
          borderRadius: "10px"
        }}> 
        <Auth
          supabaseClient={supabase}
          view="sign_in"
          appearance={{
            theme: ThemeSupa,
            style: {
              button: {
                borderRadius: "40px",
                borderColor: 'lightgray',
              },
            },
            variables: {
              default: {
                colors: {
                  brand: "#5857FF",
                  brandAccent: `gray`,
                },
              },
            },
          }}
          providers={['google',]}
          // providers={['apple', 'google', 'github']}
          socialLayout="vertical"
        />
      </div>
    </div>
  );
};

export default SignUp;