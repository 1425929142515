import React from 'react';

const blueBackground = "hsla(214, 59%, 15%, 1)";
const blueForeground = "hsla(210, 100%, 66%, 1)";

const Free = () => {
  return (
    <div style={{ 
      height: 20,
      width: 100,
      position: 'relative', 
      backgroundColor: blueBackground,
      borderRadius: 50,
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
      }}>
        <span style={{color: blueForeground, fontFamily: "Geist Mono"}}>free account</span>
    </div>
  );
};

const Paid = () => {
  return (
    <div style={{ height: 200, width: '100%', border: '1px solid #ccc', borderRadius: 5, position: 'relative' }}>
      <div style={{ position: 'absolute', bottom: 5, right: 5, fontSize: 12 }}>Paid</div>
      <div style={{ height: 10, width: '100%', backgroundColor: '#f0f0f0', borderRadius: 5, marginTop: 20 }}>
        <div style={{ height: '100%', width: '50%', backgroundColor: '#28a745', borderRadius: 5 }}></div>
      </div>
    </div>
  );
};

const AccountStatus = ({ accountStatus }) => {
  return (
    <div
      style={{
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        left: 12,
        bottom: 12,
        height: 70,
        width: 'calc(100% - 24px)',
      }}
    >
      <span style={{ color: 'var(--text-color-dark-mode)', fontSize: '10px' }}>
        {accountStatus === 'free' ? <Free /> : accountStatus === 'paid' ? <Paid /> : null}
      </span>
    </div>
  );
};

export default AccountStatus;