import * as PIXI from 'pixi.js';

export const addHoverEffect = (geneGraphic, gene, genomeGroup, isDarkMode) => {
    geneGraphic.interactive = true;
    geneGraphic.buttonMode = true;
    let hoverTimer;

    geneGraphic.on('mouseover', (event) => {
        hoverTimer = setTimeout(() => { // Start a timer
            const localPosition = event.data.getLocalPosition(genomeGroup);

            const geneNameText = new PIXI.Text(gene.name, {
                fontFamily: 'Arial', 
                fontSize: 14, 
                fill: isDarkMode ? 0xD9DDDC : 0x000000, 
                align: 'center'
            });

            geneNameText.x = localPosition.x;
            geneNameText.y = localPosition.y - 20;

            genomeGroup.addChild(geneNameText);
            geneGraphic.geneNameText = geneNameText; // Store reference
        }, 444); // Set timeout for .5 second
    });

    geneGraphic.on('mouseout', () => {
        clearTimeout(hoverTimer); // Clear the timer if mouseout before 1 second
        if (geneGraphic.geneNameText) {
            genomeGroup.removeChild(geneGraphic.geneNameText);
        }
    });
};
