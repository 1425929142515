import React, { useState, useEffect, useCallback } from 'react';
import '../style/controlPanel.css';
import { useAuth0 } from "@auth0/auth0-react";
// import { DynamoDBClient } from "@aws-sdk/client-dynamodb";
// import { DynamoDBDocumentClient, GetCommand, UpdateCommand } from "@aws-sdk/lib-dynamodb";
import AWS from 'aws-sdk';
import { motion, AnimatePresence } from 'framer-motion';
import triggerNucRibbonsEdgeFunction from '../utilities/triggerNucRibbonsEdgeFunction'; // Adjust the path as necessary
// import { downloadNucRibbons } from '../utilities/DownloadGeneMaps';
import { supabase } from '../utilities/supabaseClient'
import { v4 as uuidv4 } from 'uuid';
import { GanttChart, ScrollText, Group, RailSymbol, Check, Search } from 'lucide-react';
import LoadingDots from '../components/LoadingDots'
// const currentDateTime = new Date().toISOString(); 
import { ViewButton, FunctionButton, SettingsButton, SharingButton } from '../components/ControlPanelButtons';
import { useAuth } from '../utilities/AuthContext';

// Assuming you want this inside your ControlPanel component
const s3 = new AWS.S3({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION,
});

const defocus = "rgba(33, 35, 45, 1)";
const focus = "rgb(38, 39, 53)";
const fontDefocus = "rgb(133, 134, 153)";
const fontFocus = "rgb(210, 211, 224)";

const lambda = new AWS.Lambda();

function ControlPanel({ 
  genomeJSON, 
  visibleGenomeJSON, 
  selectedFiles,
  sequenceView,
  setSequenceView,
  setClickedViewerSettings,
  clusterData,
  setClusterData,
  setNucJSON,
  nucJSON,
  setAlnTSV,
  setSelectedDatabase,
  selectedDatabase,
  setUserData,
  userData,
  setSharedURL,
  numberOfClusters,
  setSeachClicked
}) {
  const { user, isAuthenticated } = useAuth0();
  const [isLoading, setIsLoading] = useState(true);
  const [clusterLoading, setClusterLoading] = useState(false);
  const [syntenyLoading, setSyntenyLoading] = useState(false);
  const [sharedUID, setSharedUID] = useState(null);
  const [activeView, setActiveView] = useState('sequence');
  const { session, logOut } = useAuth();

  const downloadFile = useCallback((objectKey) => {
    return new Promise((resolve, reject) => {
      const s3Bucket = "phamlite-bucket";
      const params = {
        Bucket: s3Bucket,
        Key: objectKey,
      };
      s3.getObject(params, (err, data) => {
        if (err) {
          console.error("There was an error downloading the file: ", err);
          reject(err); 
          return;
        }
  
        try {
          const jsonData = JSON.parse(data.Body.toString('utf-8'));
          // Attach the objectKey as the "jsonPath" key
          jsonData.jsonPath = objectKey; 
          resolve(jsonData);
        

        } catch (e) {
          console.error("There was an error parsing the downloaded data: ", e);
          reject(e); 
        }
      });
    });
  }, []);
  
  const downloadTSV = useCallback((objectKey) => {
    return new Promise((resolve, reject) => {
      const s3Bucket = "phamlite-bucket";
      const params = {
        Bucket: s3Bucket,
        Key: objectKey,
      };
      s3.getObject(params, (err, data) => {
        if (err) {
          console.error("There was an error downloading the file: ", err);
          reject(err);
          return;
        }
  
        // Assuming alignment file is plain text or TSV
        const fileData = data.Body.toString('utf-8'); 
  
        resolve(fileData); // Resolve the promise 
      });
    });
  }, []);


  const handleViewerClick = () => {
    setClickedViewerSettings(prevState => !prevState); // Toggle the state
  };

  useEffect(() => {
    // fetch(`http://localhost:8787/shared/${sharedUID}`)
    if (sharedUID){
      fetch(`https://sharedlink.mjohnson3669.workers.dev/shared/${sharedUID}`)
        .then(response => response.text())
        .then(data => {
          console.log("data from cf worker",data)
        })
        .catch(error => {
          console.error('There was an error fetching the data: ', error);
        });
    }
  }, [sharedUID]); 

  const handleShareClick = async ({ selectedFiles }) => {
    console.log("selectedFiles", selectedFiles);
    
    const UID = uuidv4();

    const insertData = selectedFiles.map(file => ({
      UID: UID,
      S3Key: file
    }));
  
    const { data, error } = await supabase
      .from('sharedData')
      .insert(insertData);
  
    if (error) {
      console.error('Error inserting data into Supabase:', error);
    } else {
      console.log('Successfully inserted data:', data);
      setSharedUID(UID);
      setSharedURL(`localhost:3000/shared/${UID}`);
    }
  };

  function generateSessionId() {
    return Math.random().toString(36).substring(2, 15);
  }

  
  const handleClusterClick = async ({ session }) => {
    setClusterLoading(true);
    const sessionId = generateSessionId();
    const params = {
      FunctionName: 'cluster-proteins',
      InvocationType: 'RequestResponse',
      LogType: 'None',
      Payload: JSON.stringify({
        s3_paths: selectedFiles,
        user_session_id: sessionId,
        userSub: session.user.id,
      }),
    };
    try {
      const data = await lambda.invoke(params).promise();
      const payload = JSON.parse(data['Payload']);
      const objectKey = payload['body']['output_s3_path'];
      const jsonData = await downloadFile(objectKey);
      console.log("Cluster data:", jsonData);
      setClusterData(jsonData);
      const realnObjectKey = payload['body']["output_realn_path"];
      const alnTSV = await downloadTSV(realnObjectKey);
      setAlnTSV(alnTSV);
    } catch (err) {
      console.log(err, err.stack);
    } finally {
      setClusterLoading(false);
    }
  };

  const handleSyntenyClick = async ({ session }) => {
    const userSessionId = "test-session-id"; // Replace with your user session ID
  
    if (visibleGenomeJSON.length > 1) {
      setSyntenyLoading(true);
  
      // Now using async/await syntax for clarity
      try {
        const combinedNucJSON = await triggerNucRibbonsEdgeFunction(userSessionId, session.user.id, visibleGenomeJSON);
  
        setNucJSON(combinedNucJSON); // Updating state with the combined JSON
        setSyntenyLoading(false);
  
      } catch (error) {
        console.error("Error in triggering Nuc Ribbons function", error);
        setSyntenyLoading(false);
      }
    }
  };
  const handleViewClick = (view) => {
    setActiveView(view);
    setSequenceView(view === 'sequence');
  };

  return (
    <>
      <motion.div className="control-panel-container">
        {visibleGenomeJSON && visibleGenomeJSON.length > 0 && (
          <AnimatePresence>
            <motion.div
              className="selection-settings-panel"
              initial={{ opacity: 0.5, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10}}
              transition={{ duration: 0.3 }}
            >
              <div style={{ display: "flex", flexDirection: "row", gap: 20 }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span style={{ color: fontDefocus, fontSize: 12 }}>View</span>
                  <div className="view-button-div">
                    <ViewButton
                      onClick={handleViewClick}
                      active={activeView === 'sequence'}
                      icon={<ScrollText size={18} color={fontDefocus} />}
                      name="sequence"
                    />
                    <ViewButton
                      onClick={handleViewClick}
                      active={activeView === 'gantt'}
                      icon={<GanttChart size={18} color={fontDefocus} />}
                      name="gantt"
                    />
                  </div>
                </div>
                <AnimatePresence>
                  {!sequenceView && (
                    <motion.div
                      key="functions"
                      style={{ display: "flex", flexDirection: "column" }}
                      initial={{ opacity: 0.3, y: -10, transition: { duration: 0.2 } }}
                      animate={{ opacity: 1, y: 0, transition: { duration: 0.2 } }}
                      exit={{ opacity: 0.3, y: -10, transition: { duration: 0.2 } }}
                    >
                      <span style={{ color: fontDefocus, fontSize: 12 }}>Functions</span>
                      <div style={{ display: "flex", flexDirection: "row", gap: 6 }}>
                        <FunctionButton
                          onClick={() => setSeachClicked(true)}
                          loading={null}
                          result={null}
                          icon={<Search size={18} color={fontDefocus} />}
                          text="Search"
                          resultText={`(${numberOfClusters}) clusters`}
                        />
                        <FunctionButton
                          onClick={clusterData ? null : () => handleClusterClick({ session })}
                          loading={clusterLoading}
                          result={clusterData}
                          icon={<Group size={18} color={fontDefocus} />}
                          text="Cluster proteins"
                          resultText={`(${numberOfClusters}) clusters`}
                        />
                        {selectedFiles.length > 1 && (
                          <FunctionButton
                            onClick={nucJSON ? null : () => handleSyntenyClick({ session })}
                            loading={syntenyLoading}
                            result={nucJSON}
                            icon={<RailSymbol size={18} color={fontDefocus} />}
                            text="Find synteny"
                            resultIcon={<Check size={18} color="hsla(210, 100%, 66%, 1)" />}
                            resultText="Done"
                          />
                          )}
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
              <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end" }}> 
                <div style={{ display: "flex", flexDirection: "row", gap: "10px", alignSelf: "center" }}> {/* Use alignSelf if you want to center the buttons horizontally */}
                  <SettingsButton onClick={handleViewerClick} />
                  <SharingButton onClick={() => handleShareClick({ selectedFiles })} />
                </div>
              </div>
            </motion.div>
          </AnimatePresence>
        )}
      </motion.div>
    </>
  );
}

export default ControlPanel;

