import React, { useRef, useEffect, useState, useContext  } from 'react';
import { Application } from 'pixi.js';
import { BoxCoordsContext } from '../utilities/BoxCoordsContext';
import { drawGenomeGroups } from '../utilities/DrawGenomeGroups';

const OrfMapViewer = ({ 
    visibleGenomeJSON,  
    nucJSON, 
    windowSize, 
    sliderValues, 
    setBoxCoords, 
    selectedGenes, 
    setSelectedGenes, 
    viewerWidth, 
    viewerHeight, 
    disableDrag, 
    setDisableDrag, 
    isDarkMode, 
    clickedGeneFamily, 
    setClickedGeneFamily,
    sequenceView,
    setScrollY,
    setScrollX,
    scrollY,
    scrollX,
    setScale,
    scale,
    clusterData,
    setClusterData,
    setNucJSON,
    displacement,
    setDisplacement,
    setNumberOfClusters   
}) => {
    const boxCoords = useContext(BoxCoordsContext);
    const [maxGenomeLength, setMaxGenomeLength] = useState(0);
    const [zoomOnGene, setZoomOnGene] = useState(false);
    const [zoomInCount, setZoomInCount] = useState(0); // New state for counting zoom in presses
    const [minScale, setMinScale] = useState(0); // State to hold minimum scale
    const [isGraphicalMode, setIsGraphicalMode] = useState(true); // State to toggle between graphical and text representation
    const [indexOfSelectedGenome, setIndexOfSelectedGenome] = useState(null);
    const [wrapGenome, setWrapGenome] = useState(false);

    useEffect(() => {
        const calculatedMaxGenomeLength = visibleGenomeJSON.reduce((max, genome) => Math.max(max, genome.genome_len), 0);
        setMaxGenomeLength(calculatedMaxGenomeLength);

    }, [visibleGenomeJSON]);
    
    useEffect(() => {
        // Calculate and set minScale once maxGenomeLength and viewerWidth are available
        const newMinScale = (viewerWidth - 10) / maxGenomeLength;
        setMinScale(newMinScale);
        // setScale(newMinScale); // Set the initial scale to minScale
        setScale(0.01); // Set the initial scale to minScale
    }, [maxGenomeLength, viewerWidth, setScale]);
    
    useEffect(() => {
        if (selectedGenes.length === 0) {
            setZoomOnGene(false);
            setClickedGeneFamily(null);
        }

    }, [selectedGenes, scale, setClickedGeneFamily]);

    const containerRef = useRef(null);
    const appRef = useRef(null);
    const resizeHandlerRef = useRef(null);

    function onWheel(event) {
        event.preventDefault(); // Prevent default scrolling behavior
        const deltaX = event.deltaX;
        const deltaY = event.deltaY;
    
        if (Math.abs(deltaX) < Math.abs(deltaY)) {
            const boxes = appRef.current.stage.children;
            boxes.forEach((box) => {
            box.y -= deltaY;
            });
        }
    
    }

    useEffect(() => {
        const container = containerRef.current;
        const resolution = window.devicePixelRatio || 1;
        (async () => {
        const app = new Application();
        await app.init({
            width: container.clientWidth,
            height: container.clientHeight,
            backgroundAlpha: 0,
            preference: "webgpu",
            resolution: resolution,
            autoDensity: true,
            antialias: true
        });

      app.canvas.interactive = true;
      app.canvas.addEventListener('wheel', onWheel, { passive: false }); // Add passive: false
      console.log("visibleGenomeJSON length", visibleGenomeJSON.length)
      if (visibleGenomeJSON.length === 1) {
          console.log("SINGLE GENOME")
          setIsGraphicalMode(false);
          console.log(visibleGenomeJSON[0].genome_len)
          console.log(viewerWidth)
          console.log(visibleGenomeJSON[0].genome_len * scale)
          if (visibleGenomeJSON[0].genome_len * scale > viewerWidth){
            setWrapGenome(true);
            console.log("wrapping")
          } else {
            setWrapGenome(false);
            console.log("not wrapping")
          }
      } else {
          setWrapGenome(false);
      }
      const genomesContainer = drawGenomeGroups({
        genomeJSON: visibleGenomeJSON, 
        nucJSON: nucJSON, 
        sliderValues: sliderValues, 
        scale: scale, 
        boxCoords: boxCoords, 
        selectedGenes: selectedGenes, 
        viewerWidth: container.clientWidth, // changed
        viewerHeight: viewerHeight,
        setSelectedGenes: setSelectedGenes,
        zoomOnGene: zoomOnGene, 
        zoomInCount: zoomInCount,
        yScrollOffset: scrollY,
        setDisplacement: setDisplacement,
        displacement: displacement,
        clickedGeneFamily: clickedGeneFamily,
        isDarkMode: isDarkMode,
        clusterData: clusterData,
        setClusterData: setClusterData,
        setNucJSON: setNucJSON,
        scrollX: scrollX,
        setIndexOfSelectedGenome: setIndexOfSelectedGenome,
        indexOfSelectedGenome: indexOfSelectedGenome,
        wrapGenome: wrapGenome,
        setNumberOfClusters: setNumberOfClusters,
        });
        
      app.stage.addChild(genomesContainer);

      container.appendChild(app.canvas);
      appRef.current = app;

      const resizeHandler = () => {
        app.renderer.resize(container.clientWidth, container.clientHeight);
        console.log(container.clientWidth, container.clientHeight)

      };
      resizeHandlerRef.current = resizeHandler;
      window.addEventListener('resize', resizeHandler);
    })();
    return () => {
      console.log("cleanup")
      const resizeHandler = resizeHandlerRef.current;
      if (resizeHandler) {
        window.removeEventListener('resize', resizeHandler);
      }
    
      const app = appRef.current;
      if (app) {
        app.canvas.removeEventListener('wheel', onWheel);
        app.stage.removeChildren();
    
        // Destroy the renderer and the application
        app.destroy(true, {
          children: true,
          texture: true,
          baseTexture: true,
        });
      }
    };
  }, [visibleGenomeJSON, nucJSON, clusterData, wrapGenome]);

  return <div ref={containerRef} style={{ width: '100%', height: '100%' }} />;
};

export default OrfMapViewer;