import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { DynamoDBClient } from "@aws-sdk/client-dynamodb";
import { DynamoDBDocumentClient, GetCommand, UpdateCommand } from "@aws-sdk/lib-dynamodb";
import { Database, Triangle, MoreHorizontal } from 'lucide-react';
import { supabase } from '../utilities/supabaseClient'
import AccountStatus from '../components/AccountStatus';
import { useAuth } from '../utilities/AuthContext';
// const { session, logOut } = useAuth();

const DatabaseList = ({ setVisibleGenomeJSON, 
                        setSelectedFiles, 
                        selectedDatabase, 
                        setSelectedDatabase, 
                        userData, 
                        setUserData, 
                        setIsExtensionVisible,
                        setExtensionWidth, 
                        searchInput, 
                        setSettingClicked, 
                        setSettingClickedLoc,
                        settingClickedLoc,
                        setSettingType,
                        settingType,
                        setFocusDB,
                        focusDB,
                        genomeJSON,
                        databases,
                        setDatabases,
                        triggerDatabaseFilesRefresh,
                        filesCache,
                        setFilesCache,
                        setAccountStatus,
                        accountStatus}) => {

  const [isLoading, setIsLoading] = useState(true);
  const [isListExpanded, setIsListExpanded] = useState(true); // Track if the database list is expanded
  const [hoveredItem, setHoveredItem] = useState(null);
  const [clickedItem, setClickedItem] = useState(null);
  const [editedName, setEditedName] = useState("");
  const [addingNewDatabase, setAddingNewDatabase] = useState(false);
  const [newDatabaseName, setNewDatabaseName] = useState("");
  const { session, logOut } = useAuth();

  const createInitialDatabaseForUser = async () => {

    const { error } = await supabase
      .from('database_names')
      .insert([{ db_name: "my first database" }]);
    if (error) {
      console.error('error', error);
    }
  };

  const fetchUserDatabases = async () => {
    const { data, error } = await supabase
      .from('database_names')
      .select('*')
    if (error) {
      console.error('error', error);
      return null;
    }
    return data;
  };

  const fetchDatabases = async () => {
    let userDatabases = await fetchUserDatabases();
    if (userDatabases === null || userDatabases.length === 0) {
      console.log("No databases found for this user");
      await createInitialDatabaseForUser();
      // Fetch again after creating
      userDatabases = await fetchUserDatabases();
    }
    setDatabases(userDatabases);
    setAccountStatus("free");
  };

  useEffect(() => {
    if (!session.user.id) {
        // setIsLoading(false);
        return;
    } 
    console.log("fetching databases...")
    fetchDatabases()
  
  }, [session]);

  useEffect(() => {
    if (supabase) {
      const fetchUserFiles = async () => {
        if (!session.user.id) return;
        // Get all db_name_id for user
        const { data: db, error: err } = await supabase
          .from('database_names')
          .select('db_name_id');
          // .eq('user_id', session.user.id);
        if (err) {
          console.error('Error fetching database names:', err);
          return;
        }  
        // Get all files for each db_name_id and get the cumulative size for all files associated with user
        let totalSizeBytes = 0;
    
        for (const dbEntry of db) {
          const { data: fileSizes, error: fetchError } = await supabase
            .from('database_files')
            .select('fileSize')
            .eq('db_name_id', dbEntry.db_name_id);
    
          if (fetchError) {
            console.error('Error fetching user files:', fetchError);
            continue;
          }
    
          const dbSizeBytes = fileSizes.reduce((total, file) => total + file.fileSize, 0);
          console.log(dbSizeBytes)
          totalSizeBytes += dbSizeBytes;
        }
        const totalSizeGB = (totalSizeBytes / (1024 * 1024 * 1024)).toFixed(2);
        // const totalSizeMB = (totalSizeBytes / (1024 * 1024)).toFixed(2);
    
        console.log(`Total file size for all databases:`);
        console.log(`${totalSizeGB} GB`);
        // console.log(`${totalSizeMB} MB`);
  
      };
    
      fetchUserFiles();
    }
  }, [genomeJSON, session.user.id]);

  useEffect(() => {
      if (databases && databases.length > 0) {
          // Sort databases by `time_created` in descending order
          const sortedDatabases = databases.sort((a, b) => {
              const dateA = new Date(a.time_created);
              const dateB = new Date(b.time_created);
              return dateB - dateA; // For descending order
          });
        
      } else {
        console.log("No databases found for this user.");
      }
  }, [databases]);

  const addNewDatabase = async (newDatabaseName) => {
    if ( !session.user.id) {
      console.log('User is not authenticated or `user.sub` is not available.');
      return;
    }
    const { error: insertError } = await supabase
      .from('database_names')
      .insert([
        { db_name: newDatabaseName }
        // { user_id: session.user.id, db_name: newDatabaseName }
      ]);
    if (insertError) {
      console.log('Error inserting new database:', insertError);
    } else {
      fetchDatabases();
      setVisibleGenomeJSON(null);
      setSelectedFiles([]);
      setIsExtensionVisible(true);
      setExtensionWidth(200);
    }
  };
    
  const handleDatabaseClick = (database) => {
    // Check if the clicked database is different from the currently selected one
    if (selectedDatabase?.db_name_id !== database.db_name_id) {
      // Update selected database to show its files
      setSelectedDatabase(database);
      setVisibleGenomeJSON(null); // Clear visible genome data when switching databases
      setSelectedFiles([]); // Clear selected rows when switching databases
      setIsExtensionVisible(true); // Ensure left extension is visible
      setExtensionWidth(200);
      setSettingType(null); // Reset settingType only if the database is different
    }
  };

  const filteredDatabases = Array.isArray(databases) ? databases
    .filter(database => {
      // Compare the database name and search input case-insensitively
      return database.db_name.toLowerCase().includes(searchInput.toLowerCase());
    }) : []; // This now contains full database objects that match


  // Toggle list expanded/collapsed state
  const toggleList = () => {
    setIsListExpanded(!isListExpanded);
  };
  const updateDatabaseName = async (db_name_id, newDatabaseName) => {
    if (!session.user.id) {
      console.log('User is not authenticated.');
      return;
    }
    // Proceed with updating the databaseName in Supabase
    const { error } = await supabase
      .from('database_names')
      .update({ db_name: newDatabaseName })
      .match({ db_name_id: db_name_id });
  
    if (error) {
      console.error('Error updating database name:', error);
      return;
    }
  
    fetchDatabases();
  };
  
  useEffect(() => {
    setEditedName(selectedDatabase?.databaseName);
  }, [selectedDatabase])

  const handleNameChange = (e) => {
    // Only remove new lines, allow spaces within the input
    const sanitizedInput = e.target.value.replace(/\n/g, '');
    setEditedName(sanitizedInput);
  };
  
  const saveChanges = (db_name_id, editedName) => {
    // Trim to ensure no leading/trailing spaces in the final saved name
    const sanitizedFinalName = editedName.trim();
    updateDatabaseName(db_name_id, sanitizedFinalName);
    setSettingType(null);
  };

  async function deleteDatabase(db_name_id) {
    // Ensure that the db_name_id is provided
    if (!db_name_id) {
      console.error('Error: db_name_id is required for deleting a database.');
      return;
    }
  
    // Attempt to delete the database with the given db_name_id
    const { data, error } = await supabase
      .from('database_names')
      .delete()
      .match({ db_name_id: db_name_id });
  
    if (error) {
      console.error('Error deleting database:', error);
      return;
    }
  
    console.log('Database deleted successfully:', data);
    // You might want to refresh your list of databases here to reflect the deletion
  }
  
  return (
    <div 
      style={{
        display: "flex", 
        flexDirection: "column", 
        height: "auto", 
        width: "100%",
        overflowY: "scroll",
        }}>
      <motion.div
        onClick={toggleList}
        style={{height: "20px", display: "flex", flexDirection: "row", gap: "10px", alignItems: "center"}}
        whileHover={{ backgroundColor: "rgba(38, 39, 53, 1)"}}>
        <Database size={12} color="var(--secondary-color-dark-mode)" strokeWidth={2}/>
        <span style={{color: "var(--secondary-color-dark-mode)", fontSize: "12px"}}>Databases</span>
        <Triangle
          size={7}
          color="var(--secondary-color-dark-mode)"
          strokeWidth={3}
          style={{
            rotate: isListExpanded ? '180deg' : '90deg', // Rotate based on expanded state
            originX: 'center', originY: 'center',
            transition: {duration: 1} // Animate the rotation
          }}
        />
      </motion.div>
      {/* <AnimatePresence> */}
        {isListExpanded && (
          <div
            className="database-list"
            initial={{ opacity: 0, y: -10, scale: 0.9}}
            animate={{ opacity: 1, y: 1, scale : 1}}
            exit={{ opacity: 0, y: -10, scale : 0.9, transition: { duration: 0.2}}}
          >
              {databases.length > 0 ? (
                databases.map((database, index) => (
                <motion.div 
                  key={index}
                  onClick={() => handleDatabaseClick(database)}
                  onHoverStart={() => setHoveredItem(index)}
                  onHoverEnd={() => setHoveredItem(null)}
                  style={{
                    borderRadius: "5px",
                    paddingLeft: "20px",
                    paddingTop: "5px",
                    fontSize: "12px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    paddingRight: "12px",
                    height: "25px",
                    overflowX: "scroll",
                    width: "100%",
                  }}
                animate={{ 
                  backgroundColor: selectedDatabase?.db_name_id === database.db_name_id ? "rgba(38, 39, 53, 1)" : "rgba(38, 39, 53, 0)",
                  transition: { duration: 0 }
                }}
                whileHover={{ 
                  backgroundColor: "rgba(38, 39, 53, 1)",
                }}
                >
                {(focusDB === database.db_name_id) && (settingType === "editName") ? ( //for editing database name
                  <input 
                    type="text"
                    style={{height: "20px"}}
                    value={editedName}
                    onChange={handleNameChange}
                    onBlur={() => saveChanges(database.db_name_id, editedName)} // Corrected to use a callback function
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        saveChanges(database.db_name_id, editedName); // This is fine as it's already in a conditional block
                      }
                    }}
                    autoFocus
                  />
                  ) : (focusDB === database.db_name_id) && (settingType === "deleteDB") ? (
                    <div
                    style={{
                      color: "red",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      cursor: "pointer"
                    }}
                    onClick={() => {
                      deleteDatabase(database.db_name_id);
                    }}
                    >
                    confirm delete?
                    </div>
                  ) : (
                    <span 
                    style={{
                      color: "rgb(210, 211, 224)",
                      // textDecoration: selectedDatabase?.db_name_id === database.db_name_id ? "underline solid white 1px" : "none", // Apply underline if selected
                      whiteSpace: "nowrap",
                      overflow: "hidden", 
                      textOverflow: "ellipsis" 
                    }}>
                      {database.db_name}
                    </span>
                  )}
                {hoveredItem === index && 
                  <MoreHorizontal
                    size={12} 
                    color="rgb(133, 134, 153)" 
                    strokeWidth={2}
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent click from bubbling up to the parent div
                      setClickedItem(index); // Set this item as the clicked one
                      setSettingClicked(true);
                      setFocusDB(database.db_name_id);
                      setEditedName(database.db_name);
                      const xPos = e.pageX;
                      const yPos = e.pageY;
                      setSettingClickedLoc({
                        width: '150px',
                        height: 'auto',
                        position: 'fixed',
                        backgroundColor: 'rgba(38, 39, 53, 1)',
                        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                        zIndex: 10000,
                        borderRadius: '5px',
                        border: '1px solid white',
                        display: 'block',
                        left: `${xPos}px`,
                        top: `${yPos+10}px`,
                        padding: "4px",
                        key: index,
                      });
                    }}/>
                  }
                </motion.div>
              ))
            ) : (
              <div style={{ padding: "10px", textAlign: "center" }}>No matching databases found.</div>
            )}
            {/* New database + button... */}
            {addingNewDatabase ? (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                style={{
                  borderRadius: "5px",
                  paddingLeft: "20px",
                  paddingTop: "5px",
                  fontSize: "12px",
                  paddingRight: "12px",
                  height: "25px",
                  overflowX: "scroll",
                  width: "100%"
                }}
              >
                <input
                  type="text"
                  placeholder="Enter database name"
                  value={newDatabaseName}
                  onChange={(e) => setNewDatabaseName(e.target.value)}
                  // style={{ flexGrow: 1, marginRight: "10px" }}
                  style={{height: "20px"}}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      addNewDatabase(newDatabaseName);
                      setAddingNewDatabase(false)
                      setNewDatabaseName("");
                    }
                  }}
                  autoFocus
                />
              </motion.div>
            ) : (
              <motion.div
                onClick={() => setAddingNewDatabase(true)} // Show input when clicking "Add database"
                whileHover={{ backgroundColor: "rgba(38, 39, 53, 0.5)" }}
                style={{ padding: "10px", cursor: "pointer", textAlign: "center" }}
              >
                <span style={{color: "var(--secondary-color-dark-mode)"}}>Add database</span>
              </motion.div>
            )}
          </div>
        )}
      {/* </AnimatePresence> */}
      <AccountStatus accountStatus={accountStatus} />
      {/* <div
        style={{
          position: "absolute", 
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          left: 12,
          bottom: 12,
          height: 70,
          width: "calc(100% - 24px)",
          borderRadius: 5,
          border: "1px solid var(--text-color-dark-mode)"}}>
        <span 
          style={{color: "var(--text-color-dark-mode)", fontSize: "10px"}}>
            {accountStatus}
        </span>
      </div> */}
    </div>
  );
};

export default DatabaseList;