import { Navigate, Outlet } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useAuth } from './utilities/AuthContext';

export const ProtectedRoute = () => {
  const { session, loading } = useAuth(); // Use `logOut` from the context
  if (loading) {
    return <div>Loading...</div>;
  }

  if (!session) {
    console.log("Please log in...");
    return <Navigate to="/" />;
  }

  return <Outlet />;
};
