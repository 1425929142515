import React, { useEffect, useRef } from 'react';

const PDBViewer = ({ pdbData }) => {
  const pdbRef = useRef();

  useEffect(() => {
    if (window.$3Dmol && pdbData !== '') {
      const viewer = window.$3Dmol.createViewer(pdbRef.current, {
        defaultcolors: window.$3Dmol.rasmolElementColors,
      });

        viewer.addModel(pdbData, "pdb");
        viewer.setStyle({cartoon:{}});

        //   viewer.addStyle({},{stick:{radius:.5,colorscheme:"magentaCarbon"}});
        viewer.setHoverable({},true,function(atom,viewer,event,container) {
            if(!atom.label) {
            atom.label = viewer.addLabel(atom.resn+":"+atom.atom,{position: atom, backgroundColor: 'mintcream', fontColor:'black'});
            }
        },
        function(atom) {
            if(atom.label) {
            viewer.removeLabel(atom.label);
            delete atom.label;
            }
        }
        );
        viewer.setProjection("orthographic");
        let surf = viewer.addSurface("SAS", );
        surf.then(function() {
            viewer.setSurfaceMaterialStyle(surf.surfid, {opacity:0.5});
            viewer.render();
            });
        viewer.setBackgroundColor(0x191a23, 1);

        viewer.zoomTo();
        viewer.render();
        viewer.zoom(2, 1000);                               /* slight zoom */

    }
  }, [pdbData]); // Depend on pdbText to re-render when it changes

  return <div 
            ref={pdbRef} 
            style={{ width: '100%', height: '100%', position: "relative" }}></div>;
};

export default PDBViewer;
