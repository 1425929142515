import React, { createContext, useContext, useState, useEffect } from 'react';
import { supabase } from './supabaseClient';

const AuthContext = createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
    const [session, setSession] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Correctly handle the promise from getSession
        const initSession = async () => {
            const { data: { session } } = await supabase.auth.getSession();
            setSession(session);
            setLoading(false); // Indicate the session check is complete

        };

        // Call the async function
        initSession();
        
        // This creates an event listener for auth state changes
        const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
            setSession(session);
        });

        // Cleanup the event listener when the component unmounts
        return () => {
            if (authListener) authListener.unsubscribe();
        };
    }, []);

    // Add logout function for completeness
    const logOut = async () => {
        await supabase.auth.signOut();
        // Optionally reset session state to null on logout
        setSession(null);
    };

    const value = {
        session,
        logOut,
        loading
    };

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
}