import React, { useCallback, useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import AWS from 'aws-sdk';
import { SquareStack } from 'lucide-react';
import { supabase } from '../utilities/supabaseClient';
import { useAuth } from '../utilities/AuthContext';

const DatabaseFiles = ({
  userData,
  selectedDatabase,
  setSelectedFiles,
  selectedFiles,
  setVisibleGenomeJSON,
  setClusterData,
  setNucJSON,
  setSequenceView,
  searchInput,
  setFiles,
  files,
  setScrollX,
  setScrollY,
  setDisplacement,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const lastSelectedRowRef = useRef(null);

  const s3 = new AWS.S3({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: process.env.REACT_APP_AWS_REGION,
  });

  const downloadFile = useCallback((objectKey) => {
    return new Promise((resolve, reject) => {
      const s3Bucket = process.env.REACT_APP_S3_BUCKET;
      const params = {
        Bucket: s3Bucket,
        Key: objectKey,
      };

      s3.getObject(params, (err, data) => {
        if (err) {
          console.error("There was an error downloading the file: ", err);
          reject(err);
          return;
        }

        try {
          const jsonData = JSON.parse(data.Body.toString('utf-8'));
          resolve(jsonData);
        } catch (e) {
          console.error("There was an error parsing the downloaded data: ", e);
          reject(e);
        }
      });
    });
  }, []);

  useEffect(() => {
    if (!selectedDatabase) return;
    if (supabase) {
      const fetchFiles = async (db_name_id) => {
        const pageSize = 1000;
        let page = 0;
        let allUserFiles = [];

        while (true) {
          const { data: userFiles, error: fetchError, count } = await supabase
            .from('database_files')
            .select('*', { count: 'exact' })
            .eq('db_name_id', db_name_id)
            .range(page * pageSize, (page + 1) * pageSize - 1);

          if (fetchError) {
            console.log('error', fetchError);
            break;
          }

          allUserFiles = [...allUserFiles, ...userFiles];

          if (count <= (page + 1) * pageSize) {
            break;
          }
          page++;
        }

        if (allUserFiles.length === 0) {
          console.log("No files found for this database.");
          setFiles([]);
        } else {
          console.log("length of userFiles", allUserFiles.length);
          setFiles(allUserFiles);
        }
      };
      fetchFiles(selectedDatabase.db_name_id);
    }
  }, [selectedDatabase, supabase]);

  const handleBatchDownload = useCallback((selectedRowKeys) => {
    const downloadPromises = selectedRowKeys.map(objectKey => downloadFile(objectKey));

    Promise.all(downloadPromises)
      .then(results => {
        console.log("downloaded data", results);
        setVisibleGenomeJSON(results);
      })
      .catch(error => {
        console.error("Error with batch download: ", error);
      });
  }, [downloadFile, setVisibleGenomeJSON]);

  const handleFileClick = useCallback((event, file) => {
    const objectKey = file.FileObjectKey;
    let newSelectedFiles;

    if (event.metaKey || event.ctrlKey) {
      newSelectedFiles = selectedFiles.includes(objectKey)
        ? selectedFiles.filter(key => key !== objectKey)
        : [...selectedFiles, objectKey];
    } else if (event.shiftKey && lastSelectedRowRef.current !== null) {
      const start = files.findIndex(f => f.FileObjectKey === lastSelectedRowRef.current);
      const end = files.findIndex(f => f.FileObjectKey === objectKey);
      const range = files.slice(Math.min(start, end), Math.max(start, end) + 1);
      newSelectedFiles = [...new Set([...selectedFiles, ...range.map(f => f.FileObjectKey)])];
    } else {
      newSelectedFiles = [objectKey];
      lastSelectedRowRef.current = objectKey;
    }

    setSelectedFiles(newSelectedFiles);
    setClusterData(null);
    setNucJSON(null);
    handleBatchDownload(newSelectedFiles);

    if (newSelectedFiles.length >= 1) {
      setSequenceView(false);
    }

    setScrollY(0);
    setScrollX(0);
    setDisplacement(0);
  }, [selectedFiles, files, setSelectedFiles, handleBatchDownload]);

  function formatKilobase(number) {
    const kb = (number / 1000).toFixed(1);
    return `${kb}kb`;
  }

  return (
    <>
      {files.length && selectedDatabase ? (
        <>
          <div className="database-header">
            <SquareStack size={16} color="rgb(133, 134, 153)" strokeWidth={3} />
            <div className="database-info">
              <span className="database-name">{selectedDatabase.db_name} /</span>
              <span className="file-count">({files.length})</span>
            </div>
          </div>
          <div className="files-container">
            {files.map((file, index) => (
              <motion.div
                className="files"
                key={index}
                onClick={(e) => handleFileClick(e, file)}
              >
                <motion.div
                  className="file-div"
                  initial={{ backgroundColor: 'rgb(38, 39, 53, 0)' }}
                  whileHover={{ backgroundColor: "rgb(38, 39, 53, 1)" }}
                  animate={{
                    backgroundColor: selectedFiles.includes(file.FileObjectKey) ? "rgb(38, 39, 53, 1)" : "rgb(38, 39, 53, 0)",
                    transition: { duration: 0 }
                  }}
                  onHoverStart={() => setIsHovered(true)}
                  onHoverEnd={() => setIsHovered(false)}
                >
                  <div className="file-name-container">
                    <span>{file.file_name}</span>
                  </div>
                  <div className="file-meta-div">
                    <div className="seq-num">
                      <span style={{ fontSize: 10 }}>{formatKilobase(file.sequence_length)}</span>
                    </div>
                    <div className="protein-count">
                      <span style={{ fontSize: 10 }}>{file.numberOfProteins}</span>
                    </div>
                  </div>
                </motion.div>
              </motion.div>
            ))}
          </div>
        </>
      ) : (
        <div className="no-files-message">
          <span>No files yet</span>
        </div>
      )}
    </>
  );
};

export default DatabaseFiles;