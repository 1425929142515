import React from 'react';
import { Stage, Graphics } from '@pixi/react';
import { motion } from 'framer-motion';
import { UploadCloud } from 'lucide-react';

const drawGrid = (g, viewerWidth, viewerHeight, isDarkMode, scaleChangeRatio, displacement, scrollX, scrollY) => {
  g.clear();
  const pointColor = isDarkMode ? 0x48494B : 0xD3D3D3;
  g.beginFill(pointColor);

  // Draw points
  for (let i = 0; i <= viewerWidth; i += 10) {
    for (let j = 0; j <= viewerHeight; j += 10) {
      g.drawCircle(i, j, 1);
    }
  }

  g.endFill();
};

const variants = {
  hover: {
    backgroundColor: 'rgba(192, 192, 192, 1)', // equivalent to gray
  },
  initial: {
    backgroundColor: 'rgba(255, 255, 255, 1)', // equivalent to white
  },
  darkMode: {
    backgroundColor: 'rgba(25, 26, 35, 1)', // equivalent to black
  }
};

const GridStage = ({
  viewerWidth,
  viewerHeight,
  isDragHover,
  hideGrid,
  isDarkMode,
  scale,
  displacement,
  scaleChangeRatio,
  scrollX,
  scrollY,
  infoPanelOpen,
  files
}) => {
  const getAnimationVariant = () => {
    if (isDarkMode) return 'darkMode';
    return isDragHover ? 'hover' : 'initial';
  };

  return (
    <motion.div
      className="behind-grid"
      variants={variants}
      animate={getAnimationVariant()}
      transition={{ duration: 0.344 }}
    >
      {!hideGrid && (
        <Stage
          width={viewerWidth}
          height={viewerHeight}
          options={{ backgroundAlpha: 0 }}
          style={{ position: 'absolute', bottom: 0, left: 0 }}
        >
          <Graphics
            draw={(g) =>
              drawGrid(
                g,
                viewerWidth,
                viewerHeight,
                isDarkMode,
                scaleChangeRatio,
                displacement,
                scrollX,
                scrollY
              )
            }
          />
        </Stage>
      )}
      {files.length === 0 && (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            backgroundColor: "var(--primary-color-dark-mode)",
            padding: 10,
            borderRadius: 10,
            //set border color to 0x48494B
            border: "1px solid #D9DDDC",
          }}
        >
          <UploadCloud size={48} color="#D9DDDC"/>
          <p style={{color: "#D9DDDC"}}>Drop files to get started</p>
        </div>
      )}
    </motion.div>
  );
};

export default GridStage;