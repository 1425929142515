// NavigationBar.js
import React, { useState } from 'react';
import { ChevronDown } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { useAuth } from '../utilities/AuthContext'; // Ensure path is correct

import '../style/NavigationBar.css';

const NavigationBar = () => {
  const { session, logOut } = useAuth(); // Use `logOut` from the context
  const [isOpen, setIsOpen] = useState(false); // Local state to manage dropdown visibility
  const siteUrl = process.env.REACT_APP_SITE_URL; // Dynamically get the site URL
  
  const handleLogout = async () => {
    try {
      await logOut();
      console.log("User is logged out");
      // Optionally navigate the user to a different page
    } catch (error) {
      console.error("Failed to log out:", error);
    }
  };

  return (
    <header className="sticky-header">
      <div className="header-left">genebox.app</div>
      <div className="header-buttons">
        {session && (
          <div className="user-info">
            {/* Any user info or dashboard button */}
          </div>
        )}
        <div className="header-button-panel">
          <a href={`${siteUrl}`} className="header-button">Home</a>
          {/* <a href="https://genebox.app/pricing" className="header-button">Pricing</a> */}
          {/* <a href="https://genebox.app/about" className="header-button">About</a> */}
          {session && (
            <>
            <a href={`${siteUrl}/dashboard`} className="header-button">Dashboard</a> 
            <div style={{ position: 'relative' }}>
              <motion.div
                style={{ display: 'flex', alignItems: 'center', flexDirection: "row" }}
                onClick={() => setIsOpen(!isOpen)}
                whileHover={{ scale: 1.1 }}
              >
                <a href="#!" className="header-button">Account</a>
                <ChevronDown size={20} style={{ color: "#5857FF", marginLeft: '5px' }} />
              </motion.div>
              <AnimatePresence>
                {isOpen && (
                  <motion.div
                    initial={{ opacity: 0, scale: 0.9 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.9 }}
                    transition={{ duration: 0.2 }}
                    style={{
                      position: 'absolute',
                      top: '100%',
                      left: 0,
                      background: 'white',
                      boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                      borderRadius: '4px',
                      zIndex: 10,
                    }}
                  >
                    <div className="dropdown">
                      {/* Dropdown items here */}
                      <a href="#!" className="dropdown-item" onClick={handleLogout}>Sign Out</a>
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
            </>
          )}
        </div>
      </div>
    </header>
  );
};

export default NavigationBar;