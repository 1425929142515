import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import AWS from 'aws-sdk';
// Configure AWS
AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID, // Ensure these are not exposed in client-side code
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY, // Ensure these are not exposed in client-side code
  region: process.env.REACT_APP_AWS_REGION,
});
const s3 = new AWS.S3();

const UploadFiles = async (files, presignedUrls) => {
    try {
      // Array to hold upload promises
      const uploadPromises = files.map(async (file, index) => {
        const presignedUrl = presignedUrls[index];
        const fileType = file.type;
  
        try {
          // Using fetch to PUT file to S3
          const response = await fetch(presignedUrl, {
            method: 'PUT',
            body: file, // Directly using the File object from input
            headers: {
              'Content-Type': fileType,
            },
          });
  
          if (!response.ok) {
            throw new Error(`Failed to upload ${file.name}`);
          }
  
          // Return file key if needed
          const fileKey = new URL(presignedUrl).pathname.slice(1);
          return fileKey;
        } catch (error) {
          console.error(`Error uploading ${file.name}:`, error);
          return null; // Return null on error to filter out later
        }
      });
  
      // Wait for all uploads to complete
      const uploadedFilesKeys = (await Promise.all(uploadPromises)).filter(key => key !== null);
      console.log("Uploaded files keys:", uploadedFilesKeys);
  
      // You can now use the uploaded files keys for further processing
      return uploadedFilesKeys; // or return true / false based on your logic
    } catch (error) {
      console.error('Error uploading files:', error);
      return false; // Indicate failure
    }
  };

// Refactored getPresignedUrls to use AWS SDK directly
const getPresignedUrls = async (files, userSessionId) => {
  const presignedUrls = await Promise.all(files.map(file => {
    const fileExtension = file.name.split('.').pop();
    const randomFileName = uuidv4().slice(0, 8);
    const fileName = userSessionId + '/' + randomFileName;
    const filePath = `genomes/${fileName}`; 
    const params = {
      // Bucket: process.env.S3_BUCKET, 
      Bucket: 'phamlite-bucket', 
      Key: filePath,
      Expires: 60 * 5, // URL expiration time 5 min
    };

    return new Promise((resolve, reject) => {
      s3.getSignedUrl('putObject', params, (err, url) => {
        if (err) {
          reject(err);
        } else {
          resolve(url);
        }
      });
    });
  }));

  return presignedUrls;
};

  export const UploadFilesToS3 = async ({files, setIsUploading, userSessionId}) => {
    setIsUploading(true);

    let uploadResult;

    try {
        // Get presigned URLs
        const presignedUrls = await getPresignedUrls(files, userSessionId);
        console.log(presignedUrls)
        // Upload the files directly using the presigned URLs
        uploadResult = await UploadFiles(files, presignedUrls);

        if (uploadResult.length > 0) {
            console.log('Files uploaded successfully');
            // Proceed with any post-upload logic here
        } else {
            console.error('No files were uploaded');
        }
    } catch (error) {
        console.error('Error uploading files:', error);
        throw error; // It's usually better to handle the error in the calling component or in a catch block
    } finally {
        console.log('Upload process completed');
        setIsUploading(false);
    }

    // Return the result of the upload process
    return uploadResult;
};
