import React, { createContext, useContext } from 'react';
import { useNotificationManager } from './useNotificationManager';

const NotificationsContext = createContext();

export const NotificationsProvider = ({ children }) => {
    const { notifications, addNotification, removeNotification } = useNotificationManager();

    return (
        <NotificationsContext.Provider value={{ notifications, addNotification, removeNotification }}>
            {children}
        </NotificationsContext.Provider>
    );
};

// Hook to use notifications within components
export const useNotifications = () => useContext(NotificationsContext);