import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios'; // Ensure you've installed axios

const SharedGenome = () => {
  const { uid } = useParams();
  const [dataUrls, setDataUrls] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  console.log('uid', uid);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://sharedlink.mjohnson3669.workers.dev/shared/${uid}`);
        setDataUrls(response.data); // Assuming the response data is the array of URLs
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, [uid]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading data</div>;
  console.log("dataUrls",dataUrls)
  //dataUrls is an array
  return (
    <div>
      <h2>Shared Genome Visualizations</h2>
      {dataUrls.urls.map((url, index) => (
        <span>{url}</span>
      ))}
    </div>
  );
};

export default SharedGenome;
