import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { add, remove } from "../utilities/array-utils";
import { useNotificationManager } from '../utilities/useNotificationManager.js';
import { colorMapSignal, repMemberCountMapSignal, memberToRepMapSignal } from '../utilities/DrawGenomeGroups';
import PDBViewer from './PDBViewer';

function GeneInfoCard({ 
        selectedGenes, 
        addNotification, 
        setClickedGeneFamily, 
        alnTSV,
        pdbData, 
        disableDrag, 
        enableDrag, 
        setClusterGroupClickedInfo,
        setRepCSSColor,
        setScrollX,
        viewerWidth,
        setInfoPanelOpen
        }) {
    function formatConfidence(value) {
        try {
            const confidenceFloat = parseFloat(value);
            // Round the number first and check if it's 100 after conversion
            if (Math.round(confidenceFloat) === 100) {
                return '100';
            } else {
                return confidenceFloat.toFixed(2);
            }
        } catch (error) {
            return value;
        }
    }
    const extractGeneData = (gene) => {
        return {
            name: gene.name,
            start: gene.start,
            stop: gene.stop,
            sequence: gene.sequence,
            cluster_id: gene.gene_metadata?.cluster_id,
            confidence: formatConfidence(gene.gene_metadata?.confidence),
            genomeId: gene.genomeId
        };
    };

    // Function to copy text to clipboard
    const copyToClipboard = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
            addNotification("Copied to clipboard", 1000); // Display notification on successful copy
        } catch (error) {
            addNotification("Failed to copy: " + error.message); // Display error notification
        }

    };

    // Parse TSV, filter, and update state
    const parseTSV = (tsvString) => {
        const rows = tsvString.split('\n');
        const headers = rows.shift().split('\t'); // Get headers

        return rows.map((row) => {
        const values = row.split('\t');
        return headers.reduce((obj, header, index) => {
            obj[header] = values[index];
            return obj;
        }, {});
        });
    }

    const handleClusterInfoClick = (geneData, event) => {
        console.log('click');
        console.log('click');
        console.log('click');
      
        const geneId = `${geneData.genomeId}|${geneData.name}`;
        const rep = memberToRepMapSignal.value[geneId];
        setClickedGeneFamily(geneId);
        const clusterSize = repMemberCountMapSignal.value[rep];
      
        const filteredRows = parseTSV(alnTSV).filter(row => row.cseqid === rep);
        console.log("filteredRows", filteredRows);
        setClusterGroupClickedInfo(filteredRows);
      
        const mouseXPosition = event.clientX;
        if (mouseXPosition > (viewerWidth / 2)) {
          console.log("viewerWidth", viewerWidth);
          const newScrollPosition = (viewerWidth / 2) - mouseXPosition;
          console.log("newScrollPosition", newScrollPosition);
          setScrollX(-(viewerWidth / 2));
          setInfoPanelOpen(true);
        }
      };

    // Function to render a single gene in a div
    const renderGeneInfo = (geneData) => {
        const geneName = `${geneData.genomeId}|${geneData.name}`
        const cssColor = colorMapSignal.value != null && colorMapSignal.value[geneName] ? colorMapSignal.value[geneName].replace('0x', '#') : "#000000";
        
        let memberCountForRep;
        if (colorMapSignal.value != null) {
            const repOfMember = memberToRepMapSignal.value[geneName];
            memberCountForRep = repMemberCountMapSignal.value[repOfMember];
            // setRepCSSColor(cssColor);
        }
        return (
            <div style={{display: "flex", flexDirection: "row", gap: "4px"}}>
                <div className="gene-info-div">
                    {/* CARD HEADER */}
                    <div className="card-header" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <span 
                            title={geneData.name}
                            style={{
                            fontSize: ".8rem", 
                            maxWidth: "60%", // Adjust based on your layout
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                        }}>{`>${geneData.name}`}</span>
                        <div style={{
                            display: "flex", 
                            flexDirection: "column",
                            alignItems: "flex-end",
                            maxWidth: "40%" // Adjust based on your layout
                        }}>
                            <span style={{fontSize: "0.6rem"}}>{geneData.start}..{geneData.stop}</span>
                            <span style={{fontSize: "0.6rem"}}>{geneData.sequence.length}aa</span>
                        </div>
                    </div>
                    {/* CARD BODY */}
                    <motion.div
                        className="card-body"
                        whileHover={{ backgroundColor: 'rgba(211, 211, 211, 0.5)' }}
                        initial={{ opacity: 1 }}
                        animate={{ opacity: 1 }}
                        onClick={() => copyToClipboard(geneData.sequence)}
                    >
                        <p>{geneData.sequence}</p>
                    </motion.div>
                    {/* CARD FOOTER */}
                    <div className="card-footer">
                    {colorMapSignal.value != null &&
                    <motion.div className="cluster-info" style={{width: "60%", display: 'flex', flexDirection: "row", alignItems: 'center', flexWrap: 'nowrap'}}
                        whileHover={{ backgroundColor: "rgba(211, 211, 211, 0.7)" }}
                        onClick={(event) => handleClusterInfoClick(geneData, event)} // Pass both geneData and event
                        >
                        <div style={{paddingRight: 10, width: "10px", height: "10px", borderRadius: "50%", backgroundColor: cssColor}}>   </div>
                        <motion.span
                                style={{
                                    fontSize: ".6rem", 
                                    // maxWidth: "40%", // Adjust based on your layout
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    borderRadius: '10px',
                                    padding: '2px',
                                }}
                            >
                                ({memberCountForRep}) members
                            </motion.span>
                    </motion.div>
                    }
                    <span style={{
                        display: "flex", 
                        flexDirection: "column",
                        alignItems: "flex-end",
                        maxWidth: "40%", // Adjust based on your layout
                        fontSize: ".6rem", 
                        }}>Gene conf:{geneData.confidence}
                    </span> 
                    </div>
                </div>

            </div>
        );
    };


    if (selectedGenes.length === 1) {
        const geneData = extractGeneData(selectedGenes[0]);
        return renderGeneInfo(geneData);
    } else {
        // Handle the case where there are multiple genes or none
        return <div>No gene data available or multiple genes present.</div>;
    }


}

export default GeneInfoCard;
