// DatabaseSettingsPanel.js

import React from 'react';
import { Pencil, Trash2 } from 'lucide-react';

const DatabaseSettingsPanel = ({ setSettingType, closeSettings }) => {
    return (
        <div
        style={{
            display: 'flex',
            flexDirection: 'column',    
            gap: '5px',
        }}>
        
        <div 
            style={{
                height: '20px',
                width: '100%',
                color: 'white', // Example text color, adjust as needed
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                paddingLeft: '5px',
                // justifyContent: 'center',
                fontSize: '16px', // Example font size
                cursor: 'pointer', // Changes the cursor to indicate the text is clickable
                flexDirection: 'row',
            }}
            onClick={() => {
                setSettingType("editName")
                closeSettings()
            }}
        >
            <Pencil size={12} />
            <span>Edit name</span>
        </div>
        <div 
            style={{
                height: '20px',
                width: '100%',
                color: 'white', // Example text color, adjust as needed
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                paddingLeft: '5px',
                // justifyContent: 'center',
                fontSize: '16px', // Example font size
                cursor: 'pointer', // Changes the cursor to indicate the text is clickable
                flexDirection: 'row',
            }}
            onClick={() => {
                setSettingType("deleteDB")
                closeSettings()
            }}
        >
            <Trash2 size={12} />
            <span>Delete</span>
        </div>
        </div>
    );
}

export default DatabaseSettingsPanel;