// Viewer.js
import React, { useRef, useState, useEffect } from 'react';
import { GenomeJSONToPixiJs } from '../utilities/plotter.js';
import GridStage from '../components/GridBackground';
import InteractiveComponent from '../components/InteractiveComponent'; // Assuming DragSelectBox is in the same directory
import { UploadFilesToS3 } from '../components/UploadFilesToS3';
import { v4 as uuidv4 } from 'uuid';
import { handlePusherMessage } from '../utilities/HandleUploadSessionId';
import ViewerSettings from '../components/ViewerSettings';
import { BoxCoordsContext } from '../utilities/BoxCoordsContext';
import { useNotifications } from '../utilities/NotificationsContext';
import AWS from 'aws-sdk';
import { supabase } from '../utilities/supabaseClient'
import OrfMapViewer from '../components/OrfMapViewer';
import { NucleotideSequenceDisplay } from '../utilities/SingleGenomeSequenceView';
import { useAuth } from '../utilities/AuthContext';

import { motion } from 'framer-motion';

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID, // Ensure these are not exposed in client-side code
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY, // Ensure these are not exposed in client-side code
  region: process.env.REACT_APP_AWS_REGION,
});

const lambda = new AWS.Lambda();
const s3 = new AWS.S3();

const Viewer = ({ viewerTop,
                  setViewerTop,
                  genomeJSON,
                  setGenomeJSON, 
                  setProgress, 
                  clickedViewerSettings, 
                  setClickedViewerSettings, 
                  setIsUploading, 
                  visibleGenomeJSON, 
                  setVisibleGenomeJSON,
                  sequenceView,
                  setSequenceView,
                  clusterData,
                  setClusterData,
                  nucJSON,
                  setNucJSON,
                  alnTSV,
                  setPdbData,
                  pdbData,
                  focusDB,
                  setSelectedDatabase,
                  selectedDatabase,
                  setScrollX,
                  setScrollY,
                  scrollX,
                  scrollY,
                  setSelectedFiles,
                  setDisplacement,
                  displacement,
                  infoPanelOpen,
                  setInfoPanelOpen,
                  clusterGroupClickedInfo,
                  setClusterGroupClickedInfo,
                  proteinAlignment,
                  setProteinAlignment,
                  disableDrag,
                  setDisableDrag,
                  disableScroll,
                  setDisableScroll,
                  clickedGeneFamily,
                  setClickedGeneFamily,
                  repCSSColor,
                  setRepCSSColor,
                  phylogenyData,
                  setPhylogenyData,
                  isDraggable,
                  setIsDraggable,
                  selectedGenes,
                  setSelectedGenes,
                  setTriggerDatabaseFilesRefresh,
                  filesCache,
                  setFilesCache,
                  setFiles,
                  files,
                  setNumberOfClusters
                }) => { 
  // const [genomeJSON, setGenomeJSON] = useState(null);
  const [userSessionId, setUserSessionId] = useState(null);
  const [sliderValues, setSliderValues] = useState({
    slider1: 1,  // line thickness
    slider2: 40, // Line spacing
    slider3: 0.05, // scale
    slider4: 10
  });
  const [boxCoords, setBoxCoords] = useState({left : 0, right: 0, width: 0, height: 0});
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const [viewerWidth, setViewerWidth] = useState(0); // State to store the width
  const [viewerHeight, setViewerHeight] = useState(0); // State to store the height
  const [isDragHover, setIsDragHover] = useState(false);
  const [processingStatus, setProcessingStatus] = useState(null);
  const [hideGrid, setHideGrid] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(true);
  const { notifications, addNotification } = useNotifications();
  const [activeSession, setActiveSession] = useState(null);
  const [scale, setScale] = useState(0.05); // Added state for scale 
  const [scaleChangeRatio, setScaleChangeRatio] = useState(1); // Added state for scale change ratio
  const [disableClick, setDisableClick] = useState(false);
  const viewerRef = useRef(null); // Create a ref
  const seqViewDivRef = useRef(null);
  const { session, logOut } = useAuth();
  
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      for (let entry of entries) {
        // Assuming you want to update the same viewerWidth and viewerHeight states
        if (entry.target === viewerRef.current) {
          setViewerWidth(viewerRef.current.offsetWidth);
          setViewerHeight(viewerRef.current.offsetHeight);
        }
      }
    });

    if (viewerRef.current) {
      observer.observe(viewerRef.current);
    }

    return () => {
      if (viewerRef.current) {
        observer.unobserve(viewerRef.current);
      }
    };
  }, [viewerRef, setViewerWidth, setViewerHeight]);

  const handleDragOver = (e) => {
    setIsDragHover(true); // Set to true when file is dragged over
    e.preventDefault(); // Necessary to allow for drop
  };

  const handleDragLeave = () => {
    setIsDragHover(false); // Reset when the drag leaves the component
  };
  
  const updateDatabase = async ({db_name_id, objectKey, sequenceName, sequenceLength, numberOfProteins, fileSize}) => {
    // Insert a new record into userDatabases for this userSub
    console.log("fileSize", fileSize)
    console.log("session", session)
    if (supabase) {
      const { error: insertError } = await supabase
        .from('database_files')
        .insert([
          { db_name_id: db_name_id, 
            FileObjectKey: objectKey, 
            file_name: sequenceName,
            sequence_length: sequenceLength,
            numberOfProteins: numberOfProteins,
            fileSize: fileSize}
        ])
        if (insertError) {
          console.log('error', insertError)
        }
    }
  } 
  
  const handleDrop = async (e) => {
    e.preventDefault();
    setIsDragHover(false);
    const files = e.dataTransfer.files;
    if (!files || files.length === 0) {
      return; // Exit if no files are dropped
    }
    const filesArray = Array.from(files);
    const newUserSessionId = uuidv4().slice(0, 8);
    try {
      // Upload files to S3 and process them
      const fileKeys = await UploadFilesToS3({
        files: filesArray,
        setIsUploading: setIsUploading,
        userSessionId: newUserSessionId
      });
      const downloadPromises = fileKeys.map(async (fileKey, index) => {
        const params = {
          FunctionName: 'parse_input',
          InvocationType: 'RequestResponse',
          LogType: 'None',
          Payload: JSON.stringify({
            file_key: fileKey,
            user_session_id: newUserSessionId,
          }),
        };
        // Invoke the Lambda function to process the file
        const data = await lambda.invoke(params).promise();
        const parsedPayload = JSON.parse(data.Payload);
        const parsedBody = JSON.parse(parsedPayload.body);
        const s3Bucket = "phamlite-bucket";
        const objectKey = parsedBody.fileKey;
        const sequenceName = parsedBody.sequenceName;
        const sequenceLength = parsedBody.sequenceLength;
        const numberOfProteins = parsedBody.numberOfProteins;
        // add objectKey to supabase database userFiles with db_name_id as key
        if (!objectKey) {
          console.error("Object key not found in Lambda response");
          return;
        }
        const db_name_id = selectedDatabase.db_name_id;
        const fileSize = filesArray[index].size; // Get the file size from the dropped file
        updateDatabase({db_name_id, objectKey, sequenceName, sequenceLength, numberOfProteins, fileSize});
        // need to update the cache for the selectedDatabase.db_name_id
        const paramsS3 = {
          Bucket: s3Bucket,
          Key: objectKey,
        };
        const fileData = await new Promise((resolve, reject) => {
          s3.getObject(paramsS3, (err, data) => {
            if (err) {
              reject(err);
            } else {
              resolve(data.Body.toString('utf-8'));
            }
          });
        });
        const json = JSON.parse(fileData);
        return { objectKey, json };
      });
      const results = await Promise.all(downloadPromises);
      const objectKeys = results.map((result) => result.objectKey);
      const genomes = results.map((result) => result.json);
      setGenomeJSON(genomes);
      setVisibleGenomeJSON(genomes);
      setSelectedFiles(objectKeys);
      // setFiles(objectKeys)
      fetchFiles(selectedDatabase.db_name_id);
    } catch (error) {
      console.error("An error occurred during file processing:", error);
      alert("Error processing files, please try again.");
      setProcessingStatus("error"); // Reflect error status
    } finally {
      setIsUploading(false); // Reset the upload state
      setProcessingStatus("success");
    }
  };
  const fetchFiles = async (db_name_id) => {
    const pageSize = 1000;
    let page = 0;
    let allUserFiles = [];

    while (true) {
      const { data: userFiles, error: fetchError, count } = await supabase
        .from('database_files')
        .select('*', { count: 'exact' })
        .eq('db_name_id', db_name_id)
        .range(page * pageSize, (page + 1) * pageSize - 1);

      if (fetchError) {
        console.log('error', fetchError);
        break;
      }

      allUserFiles = [...allUserFiles, ...userFiles];

      if (count <= (page + 1) * pageSize) {
        break;
      }
      page++;
    }

    if (allUserFiles.length === 0) {
      console.log("No files found for this database.");
      setFiles([]);
    } else {
      console.log("length of userFiles", allUserFiles.length);
      setFiles(allUserFiles);
    }
  };
  return (
    <BoxCoordsContext.Provider value={boxCoords}>
      <motion.div 
        ref={viewerRef} 
        className="viewer-container" 
        onDragOver={handleDragOver} 
        onDrop={handleDrop}
        onDragLeave={handleDragLeave}
        >  
        {clickedViewerSettings && 
          <ViewerSettings 
            setClickedViewerSettings={setClickedViewerSettings} 
            hideGrid={hideGrid} 
            setHideGrid={setHideGrid}
            isDarkMode={isDarkMode}
            setIsDarkMode={setIsDarkMode} 
          />}
        <GridStage  
          viewerWidth={viewerWidth} 
          viewerHeight={viewerHeight} 
          isDragHover={isDragHover}
          hideGrid={hideGrid}
          isDarkMode={isDarkMode}
          scale={scale}
          displacement={displacement}
          scaleChangeRatio={scaleChangeRatio}
          scrollY={scrollY}
          scrollX={scrollX}
          infoPanelOpen={infoPanelOpen}
          files={files}
        />
        {visibleGenomeJSON && !sequenceView ? (
          <div className="genome-viewer">
            <OrfMapViewer
              visibleGenomeJSON={visibleGenomeJSON}
              nucJSON={nucJSON}
              windowSize={windowSize}
              sliderValues={sliderValues}
              setBoxCoords={setBoxCoords}
              selectedGenes={selectedGenes}
              setSelectedGenes={setSelectedGenes}
              viewerWidth={viewerWidth}
              viewerHeight={viewerHeight}
              disableDrag={disableDrag}
              setDisableDrag={setDisableDrag}
              isDarkMode={isDarkMode}
              clickedGeneFamily={clickedGeneFamily}
              setClickedGeneFamily={setClickedGeneFamily}
              sequenceView={sequenceView}
              setScrollY={setScrollY}
              scrollY={scrollY}
              scrollX={scrollX}
              setScale={setScale}
              scale={scale}
              clusterData={clusterData}
              setClusterData={setClusterData}
              setNucJSON={setNucJSON}
              setDisplacement={setDisplacement}
              displacement={displacement}
              setNumberOfClusters={setNumberOfClusters}
            />
          </div>
        ) : (
          <div style={{position: "absolute", backgroundColor: "red", width: 100, height: 100}}>
            asldfjalsdkf
          </div>
        )}
        {/* {!sequenceView && (
          <InteractiveComponent 
            boxCoords={boxCoords}
            setBoxCoords={setBoxCoords} 
            selectedGenes={selectedGenes} 
            userSessionId={userSessionId} 
            disableDrag={disableDrag}
            setDisableDrag={setDisableDrag}
            setClickedGeneFamily={setClickedGeneFamily}
            isDarkMode={isDarkMode}
            setScrollY={setScrollY}
            scrollY={scrollY}
            setScrollX={setScrollX}
            scrollX={scrollX}
            scale={scale}
            setScale={setScale}
            alnTSV={alnTSV}
            setDisplacement={setDisplacement}
            displacement={displacement}
            viewerWidth={viewerWidth}
            visibleGenomeJSON={visibleGenomeJSON}
            scaleChangeRatio={scaleChangeRatio}
            setScaleChangeRatio={setScaleChangeRatio} 
            setPdbData={setPdbData}
            pdbData={pdbData}
            setSelectedGenes={setSelectedGenes}
            setInfoPanelOpen={setInfoPanelOpen}
            disableScroll={disableScroll}
            isDraggable={isDraggable}
            setIsDraggable={setIsDraggable}
            setDisableScroll={setDisableScroll}
            setClusterGroupClickedInfo={setClusterGroupClickedInfo}
            setProteinAlignment={setProteinAlignment}
            clusterGroupClickedInfo={clusterGroupClickedInfo}
            repCSSColor={repCSSColor}
            setRepCSSColor={setRepCSSColor}
            setDisableClick={setDisableClick}
            disableClick={disableClick}
          />
        )} */}
      </motion.div>
    </BoxCoordsContext.Provider>

  );
};

export default Viewer;

