import React from 'react';

const LeftSideSeachBar = ({searchInput, setSearchInput}) => {
    return (
        <div 
        style={{ 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'space-between', // This aligns items with space between them
            gap: '5px', // This remains your specified gap for any additional items
            height: "40px", 
            paddingRight: "12px",
            }}>
            {/* Search Box */}
            <div 
            style={{
                width: '80%',
                height: "28px",
                background: 'rgba(39, 41, 56, 1)',
                borderRadius: '5px',
                color: 'white',
            }}>
                <input 
                    type="text" 
                    placeholder="Search..." 
                    value={searchInput} // Controlled component
                    onChange={(e) => setSearchInput(e.target.value)} // Update state on change

                    style={{ 
                        width: '100%', 
                        background: 'transparent', 
                        border: 'none', 
                        color: 'white',
                        height: "20px",
                        fontSize: "12px",
                    }} 
                />
            </div>
            
            {/* Square Div */}
            <div style={{
                width: '28px',  // Adjust the width and height as needed
                height: "28px",
                background: 'rgba(39, 41, 56, 1)',
                borderRadius: '5px',

            }}>
            </div>
        </div>
    );
};

export default LeftSideSeachBar;