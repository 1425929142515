import React from 'react';
import '../style/HomeInfoPanels.css'; // Ensure your CSS is ready
import { motion, AnimatePresence } from 'framer-motion';

const HomeInfoPanels = () => {
  const panels = [
    {
      title: 'Store',
      description: 'With genebox you can safely store your genomes and retrieve them instantly.',
    },
    {
      title: 'Analyze',
      description: 'Low latency bioinformatic tools that allow you to predict coding sequences from DNA, cluster protein families, AI structure prediction and more.',
    },
    {
      title: 'Share',
      description: 'Share your genomes privately or publicly with genebox links.',
    },
    // {
    //   title: 'Pricing',
    //   description: 'Free tier for small labs with 10gb of genomic data. Pro tier for any size lab and up to 500gb of genomic data.',
    // },
  ];

  // Animation variants
  const panelVariants = {
    hidden: { opacity: 0, x: 50 }, // Start from slightly right with opacity 0
    visible: { opacity: 1, x: 0 }, // Animate to fully visible and position 0 (original position)
    exit: { opacity: 0, x: -50 }, // Exit to slightly left with opacity 0
  };

  return (
    <div className="info-panels-container">
      <AnimatePresence>
        {panels.map(panel => (
          <motion.div 
              key={panel.title} 
              className="info-panel-home"
              initial="hidden"
              animate="visible"
              exit="exit"
              variants={panelVariants}
              whileHover={{ borderColor: '#5857FF', color: '#5857FF' }}
              transition={{ duration: 0.5 }} // Adjust the duration of the animation
          >
            <h2>{panel.title}</h2>
            <p>{panel.description}</p>
          </motion.div>
        ))}
      </AnimatePresence>
    </div>
  );
};

export default HomeInfoPanels;
