import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { CornerDownLeft } from 'lucide-react';
import AWS from 'aws-sdk';

const teal = "hsla(174,90%,41%,1)";
const teal_alpha = "hsla(174,90%,41%,0.2)";
const darkTeal = "hsla(171,75%,13%,1)";

const purple = "hsla(275,80%,71%,1)";
const purple_alpha = "hsla(275,80%,71%,0.2)";
const darkPurple = "hsla(281,38%,16%,1)";

const SearchBox = ({ visibleGenomeJSON }) => {
  const [sequence, setSequence] = useState('');
  const [searchType , setSearchType] = useState('');

  const handleInputChange = async (event) => {
    const inputValue = event.target.value;
    setSequence(inputValue);

    if (isFastaFormat(inputValue)) {
      const sequenceData = extractSequenceData(inputValue);
      if (isDNASequence(sequenceData)) {
        console.log('Input is a DNA sequence');
        setSearchType("DNA");
      } else if (isProteinSequence(sequenceData)) {
        console.log('Input is a protein sequence');
        setSearchType("Protein");
      } else {
        console.log('Input is not a valid DNA or protein sequence');
        setSearchType("Invalid");
      }
    } else {
      console.log('Input is not in FASTA format');
    }
  };


  const isFastaFormat = (input) => {
    const fastaRegex = /^>\S+\s*\n[A-Za-z\s]+$/;
    return fastaRegex.test(input);
  };

  const extractSequenceData = (input) => {
    const lines = input.split('\n');
    return lines.slice(1).join('').replace(/\s/g, '');
  };

  const isDNASequence = (sequence) => {
    const dnaRegex = /^[ATCG]+$/i;
    return dnaRegex.test(sequence);
  };

  const isProteinSequence = (sequence) => {
    const proteinRegex = /^[ACDEFGHIKLMNPQRSTVWY]+$/i;
    return proteinRegex.test(sequence);
  };

  const getColorways = () => {
    if (searchType === "DNA") {
      return {
        backgroundColor: darkTeal,
        borderColor: teal,
        boxShadow: `0 0 10px 2px ${teal_alpha}`,
        iconColor: teal,
      };
    } else if (searchType === "Protein") {
      return {
        backgroundColor: darkPurple,
        borderColor: purple,
        boxShadow: `0 0 10px 2px ${purple_alpha}`,
        iconColor: purple,
      };
    } else {
      return {
        backgroundColor: darkTeal,
        borderColor: teal,
        boxShadow: `0 0 10px 2px ${teal_alpha}`,
        iconColor: teal,
      };
    }
  };

  const colorways = getColorways();
  const invokeLambdaWithSequence = async () => {
    if (!sequence || searchType === "Invalid") {
      console.error('Invalid input or no sequence to search.');
      return;
    }
  
    const lambda = new AWS.Lambda();
    const objectKeys = visibleGenomeJSON.map((genome) => genome.objectKey);
    const params = {
      FunctionName: 'BLASTp',
      InvocationType: 'RequestResponse',
      LogType: 'None',
      Payload: JSON.stringify({
        fasta_string: sequence,
        objectKeys: objectKeys
      }),
    };
  
    try {
      const data = await lambda.invoke(params).promise();
      const payloadObj = JSON.parse(data.Payload);
      console.log('Lambda function invoked successfully:', payloadObj);
      const responseBody = JSON.parse(payloadObj.body);
      console.log('Response body:', responseBody);
      // console.log('Lambda function invoked successfully:', data);
    } catch (error) {
      console.error('Error invoking Lambda function:', error);
    }
  };
  return (
    <AnimatePresence>
      {/* Container to keep items in a row */}
      <motion.div
        initial={{ y: -10, transform: 'translate(-50%, -60%)', opacity: 0 }}
        animate={{ y: 0, transform: 'translate(-50%, -50%)', opacity: 1 }}
        style={{
          position: 'fixed',
          left: '50%',
          top: '50%',
          display: 'flex', // To display items in a row
          alignItems: 'center', // Center items vertically
          justifyContent: 'center', // Center the whole block horizontally
          zIndex: 10000, // Make sure it's above or at the same layer
        }}
      >
        {/* Main Search Box */}
        <div
          style={{
            width: 550,
            height: 100,
            backgroundColor: 'var(--primary-color-dark-mode)',
            zIndex: 10000,
            borderRadius: '6px',
            border: '1px solid #D9DDDC',
            padding: '4px',
            boxShadow: '0 0 10px 2px rgba(255, 255, 255, 0.3)',
          }}
        >
          <textarea
            style={{
              width: '100%',
              height: '100%',
              backgroundColor: 'transparent',
              color: 'white',
              border: 'none',
              outline: 'none',
              resize: 'none',
              padding: '8px',
              fontFamily: 'Geist Mono Variable, monospace',
              fontSize: '12px',
            }}
            placeholder="Enter your sequence here..."
            value={sequence}
            onChange={handleInputChange}
          />
        </div>
        {/* Square div placed to the right */}
        <div
          style={{
            width: '100px',
            height: '100px',
            backgroundColor: colorways.backgroundColor,
            marginLeft: '10px',
            border: `1px solid ${colorways.borderColor}`,
            borderRadius: '6px',
            boxShadow: `0 0 10px 2px ${colorways.boxShadow}`,
            zIndex: 10001,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={invokeLambdaWithSequence} // Adding the click event listener here
        >
          <div style={{display:"flex", flexDirection:"column", gap: 2, alignItems: "center"}}>
              <span style={{fontSize: 10, color: colorways.iconColor}}>{searchType}</span>
              <CornerDownLeft size={38} color={colorways.iconColor} />
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default SearchBox;


