// GenomeNameHandler.js
import * as PIXI from 'pixi.js';

export const addGenomeName = (genomeGroup, genomeName, genomeIndex, lineSpacing, geneBoxHeight, genomeNameOffset, isDarkMode, scrollX) => {
    const fill = isDarkMode ? 0xD9DDDC : 0x000000; // white for dark mode, black otherwise
    const genomeNameText = new PIXI.Text(genomeName, {
        fontFamily: 'Arial',
        fontSize: 13,
        fill: fill,
        align: 'left'
    });

    // Adjust x and y values as needed
    genomeNameText.x = 5 - scrollX; // Set how far from the left edge
    genomeNameText.y = genomeIndex * lineSpacing + geneBoxHeight + genomeNameOffset; // Align with the genome line

    genomeGroup.addChild(genomeNameText);
};
