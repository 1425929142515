//RightSideInfoPanel.js

import React from 'react';
import { motion } from 'framer-motion';
import  ProteinAlignmentViewer  from './FastaAlignmentViewer';
import ComputePhylogeny from './ComputePhylogeny';
import TreeViewer from './TreeViewer';
import ProteinFamilyAlignment from '../components/ProteinFamilyAlignment';
import PDBViewer from '../components/PDBViewer';
import Granim from 'react-granim'
import { ArrowRightToLine, XCircle } from 'lucide-react';

const RightSideInfoPanel = ({  
                              clusterGroupClickedInfo, 
                              proteinAlignment,
                              setDisableDrag,
                              setDisableScroll,
                              setClickedGeneFamily,
                              repCSSColor,
                              visibleGenomeJSON,
                              disableDrag,
                              phylogenyData,
                              setProteinAlignment,
                              setPhylogenyData,
                              setIsDraggable,
                              pdbData,
                              setInfoPanelWidth,
                              setInfoPanelOpen,
                              selectedGenes}) => {
  // Define initial and animate states for the animation

  console.log("setClickedGeneFamily",setClickedGeneFamily)
  const animation = {
    initial: { opacity: 0, scale: 0.9 },
    animate: { opacity: 1, scale: 1 },
    transition: { duration: 0.3, ease: "easeInOut" } // Customize the transition
  };
  const handleMouseEnter = () => {
    setDisableDrag(true); // Disable drag when mouse enters the div
    setDisableScroll(true);
    console.log("INSIDE");
    };

    const handleMouseLeave = () => {
        setDisableDrag(false); // Re-enable drag when mouse leaves the div
        setDisableScroll(false);
        console.log("LEFT");
    };
    const handleMemberRowClick = (geneId) => {
        // const genomeId = geneId.split('|')[0];
        // const geneName = geneId.split('|').slice(1).join('|')
        setClickedGeneFamily(geneId);
    };
    const enableDrag = () => setIsDraggable(true);

    const handleArrowClick = () => {
        setInfoPanelOpen(false);
        // setInfoPanelWidth('0');
      };

return (
    <div style={{position: "relative", width: "100%", height: "100%"}}>
    {/* <Granim 
        style={{width: "100%", height: "100%"}} 
        id="granim"
        >
    </Granim> */}
    <motion.div 
      className='rightside-info-pop-up'
      initial={animation.initial} // Set the initial state
      animate={animation.animate} // Set the state to animate to when mounted
      transition={animation.transition} // Define the transition properties
    >
        {(pdbData !== '' && selectedGenes.length === 1) && (
            <motion.div 
                className="pdb-container"
                initial={{ scale: 0.9, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.9, opacity: 0 }}
                transition={{ type: "spring", stiffness: 100, damping: 10, mass: 1, duration: 0.2 }}
            >
                <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"center", width:"100%", height:50,paddingRight: 12,paddingTop: 12,paddingBottom:12}}>
                <XCircle size={20} color="var(--text-color-dark-mode)" onClick={handleArrowClick}/>
                <span style={{color:"var(--text-color-dark-mode)",fontSize:"10px"}}>{selectedGenes[0]?.name}</span>
                <motion.div
                    className="pdb-button"
                    whileHover={{ scale: 1.05, backgroundColor: "var(--text-color-dark-mode)" }}
                    // onClick={handleDownload}
                >
                    Download PDB
                </motion.div>
                </div>
                <div className="pdb" style={{ borderColor: "black" }} 
                    onMouseEnter={() => {
                        setIsDraggable(false); 
                    }}
                    onMouseLeave={() => {
                        setIsDraggable(true);
                    }}>
                    <PDBViewer pdbData={pdbData}/>
                </div>
            </motion.div>
        )}
        {(clusterGroupClickedInfo.length > 0 && !proteinAlignment) && (
        <div 
            className="cluster-group-info" 
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave} 
            >
            <div className='cluster-group-header' style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <span
                title={clusterGroupClickedInfo[0].cseqid.split('|').slice(1)}
                style={{
                    fontSize: "8px", 
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap'
                }}>
                Centroid: {clusterGroupClickedInfo[0].cseqid.split('|').slice(1)}
                </span>
            </div>
            <table className='table-cluster'>
                <thead>
                    <tr>
                        <th>member</th>
                        <th>% id</th>
                        <th>bit score</th>
                        <th>alignment</th>
                        <th>AA</th>
                    </tr>
                </thead>
                <tbody>
                {clusterGroupClickedInfo.map((row, index) => (
                    <motion.tr 
                        key={row.mseqid}
                        whileHover={{ backgroundColor: "rgba(211, 211, 211, 0.7)" }}
                        onClick={() => handleMemberRowClick(row.mseqid)}
                    >
                    <td title={row.mseqid.split('|').slice(1).join('|')}>
                        {row.mseqid.split('|').slice(1).join('|')}
                    </td> 
                    <td>{row.approx_pident}</td>
                    <td>{row.bitscore}</td>
                    <td>
                        <AlignmentSVG 
                            cstart={parseInt(row.cstart, 10)} 
                            cend={parseInt(row.cend, 10)} 
                            mstart={parseInt(row.mstart, 10)} 
                            mend={parseInt(row.mend, 10)} 
                            repCSSColor={repCSSColor}
                        />
                    </td>
                    <td>{calculateAALength(row.mseqid, visibleGenomeJSON)}</td>
                    </motion.tr>
                ))}
                </tbody>
            </table>
        </div>
        )}
        {proteinAlignment && (
        <div className="protein-alignment" onMouseEnter={disableDrag} onMouseLeave={enableDrag}>
        <ProteinAlignmentViewer fastaData={proteinAlignment} />
        </div>
        )}
        {phylogenyData && (
        // <div className="phylogeny" onMouseEnter={disableDrag} onMouseLeave={enableDrag}>
        <TreeViewer phylogenyData={phylogenyData} />
        // </div>
        )}
        {clusterGroupClickedInfo.length > 0 && (
        <div className='button-panel' style={{background: "rgba(245,245,220,1)", border: "2px solid rgba(98, 56, 216, 1)"}}>
        <motion.div 
            className="function-grid-button" 
            whileHover={{ scale: 1.05 }}
            >
            Table
        </motion.div>
        <motion.div className="function-grid-button" whileHover={{ scale: 1.05 }}>
            <ProteinFamilyAlignment 
                clusterGroupClickedInfo={clusterGroupClickedInfo}
                visibleGenomeJSON={visibleGenomeJSON}
                setProteinAlignment={setProteinAlignment}
            />
        </motion.div>
        <motion.div 
            className="function-grid-button" 
            whileHover={{ scale: 1.05 }}
        >
            <ComputePhylogeny 
                proteinAlignment={proteinAlignment} 
                setPhylogenyData={setPhylogenyData}
            />
        </motion.div>
        </div>
        )}
    </motion.div>
    </div>
  );
}

export default RightSideInfoPanel;

function AlignmentSVG({ cstart, cend, mstart, mend, repCSSColor }) {
    // Normalize lengths to a 40-pixel scale.
    const scaleLength = 40;
  
    // Calculate how many units each pixel represents.
    const unitsPerPixel = (cend - cstart) / scaleLength;
  
    // Compute the start and end points of the member line relative to the centroid line.
    const mLineStart = Math.max(0, cstart / unitsPerPixel);
    const mLineEnd = Math.min(scaleLength, ( cend / unitsPerPixel) + mLineStart);
  
    return (
      <svg width="40" height="10">
        {/* Centroid line (top line) */}
        <line x1="0" y1="1" x2="40" y2="1" stroke="black" strokeWidth="2" />
  
        {/* Member line (bottom line), normalized to the centroid line length */}
        <line x1={mLineStart} y1="6" x2={mLineEnd} y2="6" stroke={repCSSColor} strokeWidth="2" />
      </svg>
    );
  }
    // Function to calculate the amino acid length of a gene given its ID
    const calculateAALength = (mseqid, visibleGenomeJSON) => {
        for (const genome of visibleGenomeJSON) { // Loop through each genome object
        for (const gene of genome.genes) { // Loop through genes of each genome
            console.log(gene.name)
            console.log(mseqid.split('|').slice(1))
            if (gene.name === mseqid.split('|').slice(1).join('|')) {
            // If the gene's name matches mseqid,
            // calculate the AA length: (stop - start + 1) / 3
            const aaLength = Math.abs(gene.stop - gene.start + 1) / 3;
            // Assuming inclusive positions and that each set of 3 nucleotides encodes an AA
            return aaLength;
            }
        }
        }
    
        return 0; // Return 0 if no matching gene ID is found
    };
