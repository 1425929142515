import React, { useEffect, useState } from 'react';
import '../style/stylesheet.css';
import SwirlAnimation from '../components/Swirl';
// import Button from '../components/Button'; 
import { motion } from 'framer-motion';
import NavigationBar from '../components/NavigationBar';
import LaunchDashboardButton from '../components/LaunchDashboardButton';
import HomeInfoPanels from '../components/HomeInfoPanels'; 
import TypewriterText from '../components/TypewriterText'; 
import { Auth } from '@supabase/auth-ui-react'
import LogInButton from '../components/Loginbutton'; 
import SignUpButton from '../components/SignUpButton'; 
import { createClient } from '@supabase/supabase-js'
import { supabase } from '../utilities/supabaseClient';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { useAuth } from '../utilities/AuthContext'; // Adjust the path as necessary

const Home = () => {
  // const [session, setSession] = useState(null)
  const navigate = useNavigate(); // Create an instance of useNavigate
  const { session, logOut } = useAuth(); // Use `logOut` from the context
  console.log("session: ", session);
  useEffect(() => {
    document.title = "genebox"; 
  }, []); 
  
  // Function to handle click event and redirect
  const handleGetStartedClick = () => {
    navigate('/signup'); // Redirect to the signup page
  };
  return (
    <div>
      <div className="page-container">
        <NavigationBar session={session}/>
        <div className="view view-one">
          <div className="left-side-home">
            <div className="text-above">
              <span>genebox</span>
            </div>
            <div className="text-middle">
              <TypewriterText text="Optimize your lab." />
            </div>
            <div className="text-bottom">
              <span className='text-bottom-span'>The fastest cloud platform for genomics.</span>
              {!session && (
              <motion.div 
                className="get-started"
                whileHover={{ scale: 1.1 }}
                onClick={handleGetStartedClick} // Use the click handler here
                >
                  <span className='get-started-span'>Get started</span>
              </motion.div>
              )}
            </div>
          </div>
          <div className="right-side-home">
            <HomeInfoPanels session={session}/> 
          </div>
        </div>
      </div>
    </div>
  );
};
{/* <LogInButton/>
<SignUpButton/> */}
export default Home;


  // useEffect(() => {
  //   // // Clears all data from local storage
  //   // localStorage.clear();

  //   // // Additionally, if you're using Supabase and want to ensure you also clear any authenticated session from Supabase's end
  //   // supabase.auth.signOut().then(() => {
  //   //   // Maybe redirect the user to the login page or show a message
  //   // });
  //   supabase.auth.getSession().then(({ data: { session } }) => {
  //     setSession(session)
  //   })
  //   const {
  //     data: { subscription },
  //   } = supabase.auth.onAuthStateChange((_event, session) => {
  //     setSession(session)
  //   })
  //   return () => subscription.unsubscribe()
  // }, [])