// LeftSideHeader.js
import React, { useState, useEffect } from 'react';
import { useAuth } from '../utilities/AuthContext';
import { MoreHorizontal } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

const LeftSideHeader = ({ user }) => {
    const { session, logOut } = useAuth();
    const [validPicture, setValidPicture] = useState(session.user.user_metadata.avatar_url);
    const [nameOfUser , setNameOfUser] = useState(session.user.user_metadata.name);

    // Extract initials from user's name
    const getInitials = (name) => {
        return name
            .split(' ')
            .map(part => part[0])
            .join('')
            .toUpperCase();
    };

    // Fallback for image load error
    const handleImageError = () => {
        setValidPicture(null);
    };

    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: "100%", paddingRight: "12px",  paddingBottom: "12px" }}>
            <motion.div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '6px'}}
                whileHover>
                <span style={{ color: 'rgb(210, 211, 224)' }}>genebox</span>
                <MoreHorizontal 
                    size={12} 
                    color="rgb(133, 134, 153)" 
                    strokeWidth={2}
                />
            </motion.div>
            {/* User's profile image or initials circle */}
            {validPicture ? (
                <img
                    src={validPicture}
                    alt="User profile"
                    onError={handleImageError}
                    style={{
                        borderRadius: '50%',
                        width: '20px',
                        height: '20px',
                        objectFit: 'cover',
                    }}
                />
            ) : (
                <div style={{
                    backgroundColor: 'pink',
                    color: 'black',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '50%',
                    width: '20px',
                    height: '20px',
                }}>
                    <span style={{fontSize: "10px"}}>{getInitials(nameOfUser || 'U')}</span>
                </div>
            )}
        </div>
    );
};

export default LeftSideHeader;