// DragSelectBox.js
import React, { useState, useEffect, useRef } from 'react';
import InfoBox from './InfoBox.js';
import { motion, AnimatePresence } from 'framer-motion';
import { useNotifications } from '../utilities/NotificationsContext';
import { useGesture } from '@use-gesture/react'
import { set } from 'lodash';
import { minIndex } from 'd3';

const InteractiveComponent = ({ boxCoords,
                                setBoxCoords, 
                                selectedGenes, 
                                userSessionId, 
                                disableDrag,
                                setDisableDrag, 
                                setClickedGeneFamily, 
                                isDarkMode, 
                                setScrollY,
                                setScrollX,
                                scrollX,
                                scale,
                                setScale,
                                alnTSV,
                                setDisplacement,
                                displacement,
                                viewerWidth,
                                visibleGenomeJSON,
                                scaleChangeRatio,
                                setScaleChangeRatio, 
                                setPdbData,
                                pdbData,
                                setSelectedGenes,
                                setInfoPanelOpen,
                                disableScroll,
                                isDraggable,
                                setIsDraggable,
                                setDisableScroll,
                                setClusterGroupClickedInfo,
                                setProteinAlignment,
                                clusterGroupClickedInfo,
                                repCSSColor,
                                setRepCSSColor,
                                setDisableClick,
                                disableClick,
                            }) => {
    const [startPoint, setStartPoint] = useState({ x: 0, y: 0 });
    const [currentPoint, setCurrentPoint] = useState({ x: 0, y: 0 });
    const [isBoxDragging, setIsBoxDragging] = useState(false); // State to track dragging
    const { notifications, addNotification } = useNotifications();
    const interactiveLayerRef = useRef(null);
    const [maxGenomeLength, setMaxGenomeLength] = useState(0);
    const disableScrollRef = useRef(disableScroll); // Ref to track the current 'disableScroll' value.
    const [functionClicked, setFunctionClicked] = useState(false);  // State to control arrow direction

    const displacementRef = useRef(displacement);

    // Ensure the `displacementRef` is always updated with the latest displacement value
    useEffect(() => {
        displacementRef.current = displacement;
    }, [displacement]); // dependency array includes `displacement` to track its changes
    
    useEffect(() => {
        if (visibleGenomeJSON) {
        const calculatedMaxGenomeLength = visibleGenomeJSON.reduce((max, genome) => Math.max(max, genome.genome_len), 0);
        setMaxGenomeLength(calculatedMaxGenomeLength);
        }
    }, [visibleGenomeJSON]);
    
    const minScale = (viewerWidth - 10) / maxGenomeLength; 
    // Gesture binding
    useGesture({
        onPinch: ({ delta: [dDelta], origin: [pinchOriginX, pinchOriginY] }) => {

            // Calculate new scale immediately to use for both scale and offset calculations
            setScale(prevScale => {
                let newScale = prevScale + dDelta * 0.1;
                newScale = Math.max(newScale, minScale);
                // Use the new scale to calculate and update the offset
                setDisplacement(prevOffsetX => {
                    const scaleChangeRatioValue = newScale / prevScale;
                    setScaleChangeRatio(newScale/minScale);
                    const adjustedPinchOriginX = (pinchOriginX - prevOffsetX);
                    const newOffsetX = prevOffsetX - adjustedPinchOriginX * (scaleChangeRatioValue - 1);
                    return newOffsetX;
                });
                return newScale;
            });

        }, 
    }, {
        target: interactiveLayerRef,
        eventOptions: { passive: false }
    });
    
    const getBoxValues = () => {
        return {
            left: Math.min(startPoint.x, currentPoint.x) - scrollX - 10, // offsetting by the padding i set
            top: Math.min(startPoint.y, currentPoint.y),
            width: Math.abs(startPoint.x - currentPoint.x),
            height: Math.abs(startPoint.y - currentPoint.y),
        };
    };
    
    const handleMouseDown = (e) => {
        // setFunctionClicked(false);
        if (disableClick) {
            return; 
        }
        setIsBoxDragging(true);

        const rect = e.currentTarget.getBoundingClientRect();
        const point = {
            x: e.clientX - rect.left, 
            y: e.clientY - rect.top, // Adjust for top offset
        };
        setStartPoint(point);
        setCurrentPoint(point);
    };

    const handleMouseMove = (e) => {
        if (disableDrag || !isBoxDragging) {
            return; 
        }
        if (isBoxDragging) {
            const rect = e.currentTarget.getBoundingClientRect();
            const point = {
                x: e.clientX - rect.left,
                y: e.clientY - rect.top,
            };
            setCurrentPoint(point);
        }
    };

    const handleMouseUp = (e) => {
        if (disableDrag || e.shiftKey) {
            return; // Exit function if dragging is disabled or Shift key is pressed
        }
        setIsBoxDragging(false);
        const boxValues = getBoxValues();
        setBoxCoords(boxValues)
    };
    
    const boxStyle = {
        left: Math.min(startPoint.x, currentPoint.x),
        top: Math.min(startPoint.y, currentPoint.y),
        width: Math.abs(startPoint.x - currentPoint.x),
        height: Math.abs(startPoint.y - currentPoint.y),
    };

    const isDraggableRef = useRef(isDraggable);

    isDraggableRef.current = isDraggable;
    
    useEffect(() => {
        disableScrollRef.current = disableScroll; // Update the ref to the latest 'disableScroll' state on change
    }, [disableScroll]);
    
    useEffect(() => {
        const notificationComponent = <p style={{ fontSize: '1rem', textAlign: 'center', width: "100%", position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
          Welcome! 👋 Try dropping a FASTA nucleotide or Genbank file!
        </p>;
        addNotification(notificationComponent, 3000);
        const handleWheel = (e) => {
            if (!disableScroll) { // TODO: this isn't working
                e.preventDefault();
                
                const deltaX = e.deltaX * 0.9; // Adjust sensitivity as needed
                const deltaY = e.deltaY * 0.9;
                if (Math.abs(deltaX) > Math.abs(deltaY)) {
                    // Horizontal scroll is dominant
                    setScrollX((prevScrollX) => {
                        // Calculate potential new scroll value
                        const newScrollX = prevScrollX - deltaX;

                        // Prevent scrollX from going below 0
                        if (newScrollX + displacementRef.current > 0) {
                            return -displacementRef.current;
                        } else {
                            return newScrollX;
                        }
                    });
        
                } else {
                    // Vertical scroll is dominant
                    setScrollY((prevScrollY) => prevScrollY - deltaY);
        
                    // also update the box coords for vertical scroll
                    setBoxCoords((prevBoxCoords) => ({
                        ...prevBoxCoords,
                        top: prevBoxCoords.top - deltaY,
                    }));
                }
            }
        };
        const currentRef = interactiveLayerRef.current;
        if(currentRef) { // Check if your ref is not null
            currentRef.addEventListener('wheel', handleWheel, { passive: false });
        }
    
        // Don't forget to clean up the event listener
        return () => {
            if(currentRef) {
                currentRef.removeEventListener('wheel', handleWheel);
            }
        }
        
      }, []);

      return (
        // has a z-index of 1000
        <div  
            ref={interactiveLayerRef} 
            className='interactive-layer' 
            onMouseDown={handleMouseDown} 
            onMouseMove={handleMouseMove} 
            onMouseUp={handleMouseUp}
        >
            {isBoxDragging && (
                <motion.div 
                    className="drag-select-box"
                    style={{ ...boxStyle, border: isDarkMode ? "2px dashed white" : "2px dashed blue"}}
                    // initial={{ opacity: 0 }}
                    // animate={{ opacity: 0.5 }}
                />
            )}
            <InfoBox 
                selectedGenes={selectedGenes} 
                userSessionId={userSessionId} 
                setDisableDrag={setDisableDrag} 
                disableDrag={disableDrag}
                addNotification={addNotification} 
                setClickedGeneFamily={setClickedGeneFamily}
                alnTSV={alnTSV}
                setPdbData={setPdbData}
                pdbData={pdbData}
                setIsDraggable={setIsDraggable}
                isDraggable={isDraggable}
                setSelectedGenes={setSelectedGenes}
                visibleGenomeJSON={visibleGenomeJSON}
                setDisableScroll={setDisableScroll}
                setScrollX={setScrollX}
                viewerWidth={viewerWidth}
                scrollX={scrollX}
                setInfoPanelOpen={setInfoPanelOpen}
                setClusterGroupClickedInfo={setClusterGroupClickedInfo}
                setProteinAlignment={setProteinAlignment}
                clusterGroupClickedInfo={clusterGroupClickedInfo}
                repCSSColor={repCSSColor}
                setRepCSSColor={setRepCSSColor}
                setFunctionClicked={setFunctionClicked}
                functionClicked={functionClicked}
                setDisableClick={setDisableClick}
                disableClick={disableClick}
            />
            <div className="notifications-container">
                <ul>
                    <AnimatePresence initial={false}>
                        {notifications.map(({ id, component }) => (
                            <motion.li
                            key={id}
                            initial={{ opacity: 0, y: 50, scale: 1 }}
                            animate={{ opacity: 1, y: 0, scale: 1 }}
                            exit={{ opacity: 0, y: -50, scale: 1, transition: { duration: 1 } }} // Set duration to 1 second
                            >
                            {component}
                            </motion.li>
                        ))}
                    </AnimatePresence>
                </ul>
            </div>
        </div>
    );
};

export default InteractiveComponent;
