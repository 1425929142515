import React from 'react';
import AWS from 'aws-sdk';
const lambda = new AWS.Lambda();

const ComputePhylogeny = ({ proteinAlignment, setPhylogenyData }) => {

  const invokeLambda = async () => {

    console.log("proteinAlignment", proteinAlignment)
    let cleanedProteinAlignment = proteinAlignment.replace(/^"|"$/g, '').replace(/\\n/g, '\n');
    console.log("cleanedProteinAlignment", cleanedProteinAlignment)

    const payload = JSON.stringify({
        fastaData: cleanedProteinAlignment,
      });
    // Setup and invoke the Lambda function
    const params = {
      FunctionName: 'very-fast-tree',
      InvocationType: 'RequestResponse',
      LogType: 'None',
      Payload: payload,
    };

    try {
      const data = await lambda.invoke(params).promise();
      console.log("data", data)
      if (data.FunctionError) {
        console.error('Function error:', data.FunctionError);
        return;
      }
      const payloadObj = JSON.parse(data.Payload);
      let payloadStr = JSON.stringify(payloadObj);
      payloadStr = payloadStr.replace(/^"|"$/g, '').replace(/\\n/g, '\n');
      console.log("payloadStr", payloadStr)
      setPhylogenyData(payloadStr);

    } catch (error) {
      console.error('Lambda invocation error:', error);
    }
  };

  return (
    <div onClick={invokeLambda}>
        Phylogeny
    </div>
  );
};

export default ComputePhylogeny;
