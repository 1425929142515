import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./GenomeVisualizer/Home";
import Dashboard from "./Dashboard/dashboard";
import { ProtectedRoute } from './ProtectedRoute';
import { AnimatePresence, motion } from 'framer-motion';
import SharedGenome from './components/SharedGenomes'; // Adjust the path as necessary
import SignUp from './components/SignUp'; // Import your SignUp component here

const App = () => {
  const [showLoadingScreen, setShowLoadingScreen] = useState(false);

  // useEffect(() => {
  //   setTimeout(() => {

  //     setShowLoadingScreen(false);
  //   }, 2000); // 2000 milliseconds = 2 seconds
  // }, []);

  return (
    <Router>
      <div id="app">
          <div>
            <Routes>
              <Route path="/" element={<Home />} />
             
              <Route path="/signup" element={<SignUp />} />

              <Route path="/dashboard" element={<ProtectedRoute />}>
                <Route index element={<Dashboard />} />
              </Route>

              <Route path="/shared/:uid" element={<SharedGenome />} /> 

            </Routes>
          </div>
      </div>
  </Router>
 );
};

export default App;
