import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";


const circleVariants = {
  initial: {
    scale: 1,
    backgroundColor: "#000000",
  },
  hover: {
    scale: 35, // Large scale to fill the button
    backgroundColor: "#7A5DDB", // New color for the hover state
    transition: { duration: .3 },
  },
};

const textVariants = {
    initial: {
      color: "#000000",
      x: 0, // Starting position
    },
    hover: {
      color: "#FFFFFF",
      x: -10, // Move left by 15 pixels
      transition: { duration: 0.2 },
    },
  };
  
  
const LogInButton = () => {
  const [isHovered, setIsHovered] = useState(false);
  const { loginWithRedirect } = useAuth0();

  return (
    <motion.button
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
      className="learn-more-button"
      onClick={() =>
        loginWithRedirect({
          appState: { targetUrl: "/GenomeVisualizer" },
        })
      }
    >
      <div className="content-wrapper">
        <motion.span
          className="circle"
          variants={circleVariants}
          initial="initial"
          animate={isHovered ? "hover" : "initial"}
        />
        <motion.span
          className="learn-more-text"
          variants={textVariants}
          initial="initial"
          animate={isHovered ? "hover" : "initial"}
        >
          LOG IN
        </motion.span>
      </div>
    </motion.button>
  );
};

export default LogInButton;
