import React from 'react';
import AWS from 'aws-sdk';
import { motion } from 'framer-motion';
const lambda = new AWS.Lambda();

const ProteinFamilyAlignment = ({ clusterGroupClickedInfo, visibleGenomeJSON, setProteinAlignment }) => {
  console.log("setProteinAlignment", setProteinAlignment)

  const invokeLambda = async () => {
    
    // Initialize an array to hold sequences to align
    let sequencesToAlign = [];

    clusterGroupClickedInfo.forEach((row) => {
      const id = row.mseqid;
      const genomeId = id.split('|')[0];
      const geneName = id.split('|').slice(1).join('|');
      visibleGenomeJSON.forEach((genome) => {
        if (genome.genome === genomeId) {
          genome.genes.forEach((gene) => {
            if (gene.name === geneName) {
              console.log("found");
              // Add the gene info to the sequencesToAlign array
              sequencesToAlign.push({
                header: `>${gene.name}`,
                sequence: gene.sequence,
              });
            }
          });
        }
      });
    });

    // Construct the payload
    const payload = JSON.stringify({
      sequences_to_align: sequencesToAlign,
    });
    console.log("payload", payload)
    // Setup and invoke the Lambda function
    const params = {
      FunctionName: 'alignment_and_tree',
      InvocationType: 'RequestResponse',
      LogType: 'None',
      Payload: payload,
    };

    try {
      const data = await lambda.invoke(params).promise();
       
      if (data.FunctionError) {
        console.error('Function error:', data.FunctionError);
        return;
      }
      const payloadObj = JSON.parse(data.Payload);
      const payloadStr = JSON.stringify(payloadObj);
      setProteinAlignment(payloadStr);

    } catch (error) {
      console.error('Lambda invocation error:', error);
    }
  };

  return (
    <div onClick={invokeLambda}>
        Alignment
    </div>
  );
};

export default ProteinFamilyAlignment;
