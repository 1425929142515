import { Graphics, Container } from 'pixi.js';

function drawRibbons({ nucJSON, genomeIndexMap, scale, genomeGeneBounds, displacementX = 0 }) {
  const ribbonsContainer = new Container();

  Object.entries(nucJSON).forEach(([key, alignmentData]) => {
    const { query, subject, results } = alignmentData;
    if (Math.abs(genomeIndexMap[query] - genomeIndexMap[subject]) === 1) {
      const alignmentContainer = new Container();

      results.forEach(alignment => {
        const { query_start, query_end, subject_start, subject_end } = alignment;
        const queryGenomeIndex = genomeIndexMap[query];
        const subjectGenomeIndex = genomeIndexMap[subject];
        const queryPositionStart = query_start * scale + displacementX;
        const queryPositionEnd = query_end * scale + displacementX;
        const subjectPositionStart = subject_start * scale + displacementX;
        const subjectPositionEnd = subject_end * scale + displacementX;
        const queryY = queryGenomeIndex < subjectGenomeIndex ? genomeGeneBounds[query].bottom : genomeGeneBounds[query].top;
        const subjectY = subjectGenomeIndex < queryGenomeIndex ? genomeGeneBounds[subject].bottom : genomeGeneBounds[subject].top;
        const points = [
          { x: queryPositionStart, y: queryY },
          { x: subjectPositionStart, y: subjectY },
          { x: subjectPositionEnd, y: subjectY },
          { x: queryPositionEnd, y: queryY }
        ];

        const ribbonGraphic = new Graphics();
        const intersecting = points[1].x > points[2].x;
        if (!intersecting) {
          ribbonGraphic.moveTo(points[0].x, points[0].y);
          points.forEach(p => ribbonGraphic.lineTo(p.x, p.y));
        } else {
          const midX = (points[0].x + points[1].x) / 2;
          const midY = (points[0].y + points[1].y) / 2;
          ribbonGraphic.moveTo(points[0].x, points[0].y); // move this
          ribbonGraphic.lineTo(midX, midY);
          ribbonGraphic.lineTo(points[3].x, points[3].y); // move this
          ribbonGraphic.lineTo(points[0].x, points[0].y); //move this too

          ribbonGraphic.moveTo(points[1].x, points[1].y);
          ribbonGraphic.lineTo(midX, midY);
          ribbonGraphic.lineTo(points[2].x, points[2].y);
          ribbonGraphic.lineTo(points[1].x, points[1].y);


          ribbonGraphic.midX = midX;
          ribbonGraphic.midY = midY;
        }
        ribbonGraphic.fill({ color: 0x008000, alpha: 0.5 });
        ribbonGraphic.data = points;
        ribbonGraphic.intersecting = intersecting;
        alignmentContainer.addChild(ribbonGraphic);
      });

      ribbonsContainer.addChild(alignmentContainer);
    }
  });

  return ribbonsContainer;
}

export { drawRibbons };