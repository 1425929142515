import React from 'react';
import '../style/ProteinAlignmentViewer.css'; // Importing CSS for styling


function classifyAminoAcid(aminoAcid) {
    const polar = ['S', 'T', 'Y', 'N', 'Q'];
    const nonPolar = ['A', 'V', 'L', 'I', 'P', 'W', 'F', 'M'];
    const acidic = ['D', 'E'];
    const basic = ['R', 'H', 'K'];
    const special = ['C', 'U']; // Cysteine and Selenocysteine
  
    if (polar.includes(aminoAcid)) return 'polar';
    if (nonPolar.includes(aminoAcid)) return 'nonPolar';
    if (acidic.includes(aminoAcid)) return 'acidic';
    if (basic.includes(aminoAcid)) return 'basic';
    if (special.includes(aminoAcid)) return 'special';
  
    return ''; // Default case, might be a gap or an unrecognized character
  }
  
  function ProteinAlignmentViewer( { fastaData }) {
    function parseFasta(fasta) {
        // Normalize newline characters.
        const normalizedFasta = fasta.trim().replace(/\\r\\n|\\r|\\n/g, "\n");
    
        // Directly split the FASTA data on the assumption that each entry starts with "\n>".
        const entries = normalizedFasta.split("\n>");
    
        return entries.map((entry, index) => {
            // For the very first entry, remove an initial ">" if present.
            // This is done because the first entry might not be prefixed with "\n" due to split logic.


            entry = entry.replace(/^">/, '').replace(/"/, '');
            
    
            // Further split each entry into its header and sequence components.
            const endOfNameIndex = entry.indexOf("\n");
            let name = entry.substring(0, endOfNameIndex).trim();
            let sequence = entry.substring(endOfNameIndex + 1).replace(/\n/g, '');
    
            // Specifically for the first entry, remove leading and trailing quotation marks from the name.
            if (index === 0) {
                name = name.replace(/^"|"$/g, '');
            }
    
            return { name, sequence };
        });
    }
    
    
    
    const sequences = parseFasta(fastaData);
    return (
      <div className="alignmentContainer">
        {sequences.map((seq, index) => (
          <div key={index} className="sequenceLine">
            <span className="sequenceName">{seq.name}</span>
            {seq.sequence.split('').map((residue, index) => (
              <span key={index} className={`residue ${classifyAminoAcid(residue)}`}>
                {residue}
              </span>
            ))}
          </div>
        ))}
      </div>
    );
  }
  
  export default ProteinAlignmentViewer;