import React from 'react';
import Typewriter from 'typewriter-effect';

const TypewriterText = () => {
  return (
    <Typewriter
      options={{
        cursor: '', // This hides the cursor
      }}
      onInit={(typewriter) => {
        typewriter
          .typeString('Optimize your lab.')
          .callFunction(() => {
            console.log('String typed out!');
          })
          .pauseFor(2500)
          .deleteAll()
          .callFunction(() => {
            console.log('All strings were deleted');
          })
          .typeString('Discover new insights.')
          .pauseFor(2500)
          .deleteAll()
          .typeString('Analyze your data.')
          .start();
      }}
    />
  );
};

export default TypewriterText;
