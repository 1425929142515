import { Graphics, Text, TextStyle } from 'pixi.js';

export const drawGenomeLines = ({
  genome,
  genomeIndex,
  genomeGroup,
  scale,
  lineSpacing,
  bottomLineOffset,
  geneBoxHeight,
  genomeLineOffset,
  isDarkMode,
  viewerWidth,
  displacement,
  wrapGenome,
}) => {
  const genomeLengthInPixels = genome.genome_len * scale;
  const genomeStart = 0;
  const lineColor = isDarkMode ? 0xffffff : 0x000000;
  const yPosition = genomeIndex * lineSpacing + geneBoxHeight + genomeLineOffset; // 0 * 60 + 15 + 2
  console.log("value of wrapGenome", wrapGenome)
  if (wrapGenome) {
    console.log("wrapping...")
    const wrapCount = Math.floor(genomeLengthInPixels / viewerWidth);
  
    function getDynamicTickInterval(scale) {
        if (scale <= 0.005) {
            return 100;
        } else if (scale <= 0.05) {
            return 10;
        } else if (scale <= 0.5) {
            return 5;
        } else {
            return 1;
        }
    }
  
    let tickInterval = getDynamicTickInterval(scale);
    const textStyle = new TextStyle({
      fontFamily: 'Arial',
      fontSize: 12,
      fill: lineColor,
      align: 'center',
    });
    
    for (let wrapNumber = 0; wrapNumber <= wrapCount; wrapNumber++) {
      const lineYPosition = yPosition + wrapNumber * geneBoxHeight * 3.5;
      let wrapStartPosition = wrapNumber * viewerWidth / scale;
      let partialViewerWidth = viewerWidth; // Default to the full viewer width for most lines
    
      // Adjust the line length for the last wrap if it does not fill the entire viewer width
      if ((wrapStartPosition + viewerWidth / scale) > genome.genome_len) {
        partialViewerWidth = (genome.genome_len - wrapStartPosition) * scale;
      }
    
      // Draw the horizontal genome line for each wrap with adjusted line length for the last line
      const lineGraphic = new Graphics();
      lineGraphic.moveTo(0, lineYPosition);
      lineGraphic.lineTo(partialViewerWidth, lineYPosition);
      lineGraphic.stroke({ width: 1, color: lineColor });
      genomeGroup.addChild(lineGraphic);
    
      // Calculate the start and end position of the genome for the current wrap
      let wrapEndPosition = Math.min(genome.genome_len, ((wrapNumber + 1) * viewerWidth) / scale); // Also in genome coordinates
    
      let position = wrapStartPosition;
      while (position < wrapEndPosition) {
        let wrappedPositionX = (position * scale) % viewerWidth; // Calculate position in the current wrap
        drawTickAndLabel({
          position: position,
          yPosition: lineYPosition,
          genomeEnd: genome.genome_len,
          scale: scale,
          lineColor: lineColor,
          textStyle: textStyle,
          genomeGroup: genomeGroup,
          displacement: 0, // Adjust if your implementation requires
          actualPositionX: wrappedPositionX // This adjusts parameter to represent the correct X position
        });
        position += tickInterval * 1000; // Increment by tickInterval (in base pairs)
      }
    }
  } else {
    console.log("else block")
    const lineGraphic = new Graphics();
    lineGraphic.moveTo(displacement, yPosition);
    lineGraphic.lineTo(genome.genome_len * scale + displacement, yPosition);
    lineGraphic.stroke({ width: 1, color: lineColor });
    genomeGroup.addChild(lineGraphic);

    // Returns an appropriate tick interval (in kb) based on the scale
    function getDynamicTickInterval(scale) {
      if (scale <= 0.005) {
        return 100;
      } else if (scale <= 0.05) {
        return 10;
      } else if (scale <= 0.5) {
        return 5;
      } else {
        return 1;
      }
    }

    // Replace the static tickInterval definition with a dynamic approach
    let tickInterval = getDynamicTickInterval(scale); // Converting kb to base pairs (bp) for consistency
    const textStyle = new TextStyle({
        fontFamily: 'Arial',
        fontSize: 12,
        fill: lineColor,
        align: 'center',
      });
  
    // Directly draw the end tick for genome lengths below the tick interval, thereby skipping the first tick entirely
    if (genome.genome_len <= tickInterval * 1000) {
      drawTickAndLabel({
        position: genome.genome_len,
        yPosition: yPosition,
        genomeEnd: genome.genome_len,
        scale: scale,
        lineColor: lineColor,
        textStyle: textStyle,
        genomeGroup: genomeGroup,
        displacement: displacement,
        actualPositionX: genome.genome_len * scale // probably not correct
    });
    } else {
      // For genome lengths longer than the tick interval, start drawing from the first tick interval after genomeStart
      let position = genomeStart + tickInterval * 1000;
         // position,
        // yPosition,
        // genomeEnd,
        // scale,
        // lineColor,
        // textStyle,
        // genomeGroup,
        // displacement,
        // actualPositionX
        // Adjust our position by tickInterval until we exceed the wrapEndPosition
      // Draw ticks and labels, avoid drawing the very first tick
      while (position < genome.genome_len) {
        drawTickAndLabel({
            position: position,
            yPosition: yPosition,
            genomeEnd: genome.genome_len,
            scale: scale,
            lineColor: lineColor,
            textStyle: textStyle,
            genomeGroup: genomeGroup,
            displacement: displacement,
            actualPositionX: position * scale // probably not correct
      });
        position += tickInterval * 1000; // Move to the next tick position
      }

      // Ensure the end tick is drawn for longer genomes
    //   drawTickAndLabel(
    //     genome.genome_len,
    //     yPosition,
    //     genomeStart,
    //     genome.genome_len,
    //     scale,
    //     lineColor,
    //     textStyle,
    //     genomeGroup,
    //     tickInterval,
    //     viewerWidth,
    //     displacement
    //   );
    }

    // Draw a tick at x=0 with no text label
    const xZeroTickGraphic = new Graphics();
    xZeroTickGraphic.moveTo(displacement, yPosition);
    xZeroTickGraphic.lineTo(displacement, yPosition + 3); // Adjust the length of the tick as needed
    xZeroTickGraphic.stroke({ width: 1, color: lineColor });
    genomeGroup.addChild(xZeroTickGraphic);
  }

  return genomeLengthInPixels;
};

function drawTickAndLabel({
  position,
  yPosition,
  genomeEnd,
  scale,
  lineColor,
  textStyle,
  genomeGroup,
  displacement,
  actualPositionX
}) {
  const tickGraphic = new Graphics();
  tickGraphic.moveTo(actualPositionX + displacement, yPosition);
  tickGraphic.lineTo(actualPositionX + displacement, yPosition + 5); // Slightly longer ticks for visibility
  tickGraphic.stroke({ width: 1, color: lineColor });
  genomeGroup.addChild(tickGraphic);

  // Avoid drawing labels for the tick at the very end if it's too close to the right edge
  if (actualPositionX + displacement < genomeEnd * scale - 50) {
    const label = `${Math.round(position / 1000)}kb`;
    const text = new Text({text: label, style: textStyle});
    text.position.set(actualPositionX - text.width / 2 + displacement, yPosition + 5);
    genomeGroup.addChild(text);
  }
}
