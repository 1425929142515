export const createColorMap = (clusterData) => {
    const colorMap = {};
    const memberCount = {}; // Track the number of members associated with each representative

    const contrastingColorPalette = [
        "#e6194B", "#3cb44b", "#ffe119", "#4363d8", "#f58231",
        "#911eb4", "#46f0f0", "#f032e6", "#bcf60c", "#fabebe",
        "#008080", "#e6beff", "#9a6324", "#fffac8", "#800000",
        "#aaffc3", "#808000", "#ffd8b1", "#000075",
    ];
    
    const grayColor = "#A0A0A0"; // Gray color for reps with only one mem
    const convertTo0xFormat = (color) => `0x${color.slice(1)}`;

    // First, calculate the number of members for each representative
    clusterData.forEach(cluster => {
        const {rep, mem} = cluster;
        if (!memberCount[rep]) {
            memberCount[rep] = new Set(); // Use a Set to avoid duplicate members
        }
        memberCount[rep].add(mem);
    });

    let colorIndex = 0;
    clusterData.forEach(cluster => {
        const {rep, mem} = cluster;

        if (!(rep in colorMap)) { // Check if the rep has already been assigned a color
            if (memberCount[rep].size === 1) {
                // If there's only one member, assign gray color
                colorMap[rep] = convertTo0xFormat(grayColor);
            } else {
                // Otherwise, use the next color from the palette
                const color = convertTo0xFormat(contrastingColorPalette[colorIndex++ % contrastingColorPalette.length]);
                colorMap[rep] = color;
            }
        }
        // Assign the representative's color to the member
        colorMap[mem] = colorMap[rep];
    });

    return colorMap;
};

export const createMemberToRepMap = (clusterData) => {
    const memberToRepMap = {};

    // Map each member to its rep
    clusterData.forEach(cluster => {
        const { rep, mem } = cluster;
        memberToRepMap[mem] = rep;
    });

    return memberToRepMap;
};

export const countMembersPerRep = (memberToRepMap) => {
    const repMemberCount = {};
    let repsWithMultipleMembers = 0;
  
    // Count how many members belong to each rep
    Object.values(memberToRepMap).forEach(rep => {
      if (!repMemberCount[rep]) {
        repMemberCount[rep] = 0;
      }
      repMemberCount[rep]++;
    });
  
    // Count the number of reps with more than one member
    Object.values(repMemberCount).forEach(count => {
      if (count > 1) {
        repsWithMultipleMembers++;
      }
    });
  
    return {
      repMemberCount,
      repsWithMultipleMembers
    };
  };