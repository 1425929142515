import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { CornerDownLeft, Copy } from 'lucide-react';
import { set } from 'lodash';

const teal = "hsla(174,90%,41%,1)";
const teal_alpha = "hsla(174,90%,41%,0.2)";
const darkTeal = "hsla(171,75%,13%,1)";

const purple = "hsla(275,80%,71%,1)";
const purple_alpha = "hsla(275,80%,71%,0.2)";
const darkPurple = "hsla(281,38%,16%,1)";

const ShareBox = ({sharedURL}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleCopyClick = () => {
    navigator.clipboard.writeText(sharedURL);
  };


  return (
    <AnimatePresence>
      {/* Container to keep items in a row */}
      <motion.div
        initial={{ y: -10, transform: 'translate(-50%, -60%)', opacity: 0 }}
        animate={{ y: 0, transform: 'translate(-50%, -50%)', opacity: 1 }}
        style={{
          position: 'fixed',
          left: '50%',
          top: '50%',
          display: 'flex', // To display items in a row
          alignItems: 'center', // Center items vertically
          justifyContent: 'center', // Center the whole block horizontally
          zIndex: 10000, // Make sure it's above or at the same layer
        }}
      >
        {/* Main Search Box */}
        <div
          style={{
            width: 550,
            height: 150,
            backgroundColor: 'var(--primary-color-dark-mode)',
            zIndex: 10000,
            borderRadius: '6px',
            padding: '12px',
            boxShadow: '0 0 1px 2px rgba(255, 255, 255, 0.3)'
          }}
        >
          <motion.div
            style={{
              display: "flex",
              marginTop: "10px",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "10px",
              borderRadius: "6px",
              backgroundColor: isHovered ? "lightgray" : "rgba(38, 39, 53, 1)",
              cursor: "pointer",
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={handleCopyClick}
          >
            <span style={{ color: "#D9DDDC", fontSize: 12, fontFamily: "Geist Mono Variable" }}>{sharedURL}</span>
            <Copy size={18} color={"#D9DDDC"} />
          </motion.div>
          <span style={{ color: "#D9DDDC", fontSize: 16 }}>Share this URL</span>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default ShareBox;