import React from 'react';
import '../style/NotificationsComponent.css';

const NotificationsComponent = ({ message }) => {

    return (
        <div className="notification-container">
            {message}
        </div>
    );
};

export default NotificationsComponent;
