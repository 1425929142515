import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { parseNewick } from '../utilities/newickParser';

const TreeViewer = ({ phylogenyData }) => {
    const d3Container = useRef(null);

    useEffect(() => {
        if (phylogenyData && d3Container.current) {
            // Clear any existing SVG content
            d3.select(d3Container.current).html('');

            const treeData = parseNewick(phylogenyData);
            const margin = {top: 20, right: 90, bottom: 30, left: 90};
            const width = 200 - margin.left - margin.right; // Reduced width
            const height = 200 - margin.top - margin.bottom; // Reduced height

            // Setup base SVG
            const svg = d3.select(d3Container.current)
                .attr("viewBox", `0 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom}`)
                .append("g")
                .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

            // Create a tree layout
            const root = d3.hierarchy(treeData, d => d.children);
            const treeLayout = d3.tree().size([height, width]);
            treeLayout(root);

            // Add links (branches) - Rectangular (cladogram) style
            svg.selectAll(".link")
                .data(root.links())
                .enter()
                .append("path")
                .attr("class", "link")
                .attr("d", d => {
                    return "M" + d.source.y + "," + d.source.x
                         + "V" + d.target.x + "H" + d.target.y;
                })
                .attr("fill", "none")
                .attr("stroke", "#555");

            // Add nodes
            const node = svg.selectAll(".node")
                .data(root.descendants())
                .enter()
                .append("g")
                .attr("class", "node")
                .attr("transform", d => `translate(${d.y},${d.x})`);

            // // Add circles for nodes
            // node.append("circle")
            //     .attr("r", 2) // Slightly smaller circles
            //     .attr("fill", "#fff")
            //     .attr("stroke", "steelblue")
            //     .attr("stroke-width", "2"); // Thinner stroke

            // Add labels
            node.append("text")
                .attr("dy", "0.35em")
                .attr("x", d => d.children ? -10 : 10)
                .style("text-anchor", d => d.children ? "end" : "start")
                .style("font-size", "6px") // Set the font size to 10px
                .text(d => d.data.name);
        }
    }, [phylogenyData]);

    return <svg ref={d3Container} />;
};

export default TreeViewer;