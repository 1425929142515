function CalculateGeneCoordinates({
    gene,
    genomeIndex, 
    scale, 
    lineSpacing, 
    geneBoxHeight,
    zoomOnGene, 
    zoomInCount,
    genomeGroup,
    boxCoords,
    yScrollOffset,
    setDisplacement,
    displacement,
    wrapMap, // boolean
    arrowSize,
    viewerWidth,
}) {
    let geneLength = (gene.stop - gene.start) * scale;
    let genePositionX = (gene.start * scale) + displacement;
    let brokenGene = false;
    // Adjust genePositionY based on the wrapMap
    // let genePositionY = genomeIndex * lineSpacing;
    let genePositionY;
    let leftBit;
    let rightBit;
    let yPositionBelow;
    
    if (wrapMap) {
        const wrapCount = Math.floor(genePositionX / viewerWidth);
        genePositionY = (genomeIndex * lineSpacing) + wrapCount * (geneBoxHeight * 3.5);
        genePositionX = genePositionX % viewerWidth;
        if ((genePositionX < viewerWidth) && (genePositionX+geneLength > viewerWidth)){
            brokenGene = true;
            leftBit = viewerWidth - genePositionX;
            rightBit = geneLength - leftBit;
            yPositionBelow = genePositionY + geneBoxHeight * 3.5;
        }
    } else {
        genePositionY = genomeIndex * lineSpacing;
    }

    let points = [];
    let points2 = [];
    // If the gene is on the forward strand
    if (gene.strand === 1) {
        if (!brokenGene){
            geneLength = Math.max(geneLength, 0);
            points = [
                genePositionX, genePositionY,
                genePositionX + Math.max(geneLength - arrowSize, 0), genePositionY,
                genePositionX + geneLength, genePositionY + geneBoxHeight / 2, // Pointed end
                genePositionX + Math.max(geneLength - arrowSize, 0), genePositionY + geneBoxHeight,
                genePositionX, genePositionY + geneBoxHeight
            ];
        } else { // broken gene
            points = [
                genePositionX, genePositionY,
                genePositionX + leftBit, genePositionY,
                genePositionX + leftBit, genePositionY + geneBoxHeight,
                genePositionX, genePositionY + geneBoxHeight
            ];
            points2 = [
                0, yPositionBelow,
                Math.max(rightBit - arrowSize, 0 ), yPositionBelow,
                rightBit, yPositionBelow + geneBoxHeight / 2, // Pointed end
                Math.max(rightBit - arrowSize, 0 ), yPositionBelow + geneBoxHeight,
                0, yPositionBelow + geneBoxHeight
            ];
        }

    } else {// If the gene is on the reverse strand
        if (!brokenGene){
            points = [
                genePositionX + Math.min(arrowSize, geneLength), genePositionY,
                genePositionX + geneLength, genePositionY,
                genePositionX + geneLength, genePositionY + geneBoxHeight,
                genePositionX + Math.min(arrowSize, geneLength), genePositionY + geneBoxHeight,
                genePositionX, genePositionY + geneBoxHeight / 2 // Pointed end
            ];
        } else {
            points = [
                genePositionX + arrowSize, genePositionY,
                viewerWidth, genePositionY,
                viewerWidth, genePositionY + geneBoxHeight,
                genePositionX + arrowSize, genePositionY + geneBoxHeight,
                genePositionX, genePositionY + geneBoxHeight / 2 // Pointed end
            ];
            points2 = [
                0, yPositionBelow,
                rightBit, yPositionBelow,
                rightBit, yPositionBelow + geneBoxHeight, 
                0, yPositionBelow + geneBoxHeight
            ];

        }
    }
    
    const isBoxSelected = isGeneOverlappingBox(points, boxCoords, genomeGroup, yScrollOffset);
    // const topOfGene = genePositionY; // Top of the gene
    // const bottomOfGene = genePositionY + geneBoxHeight; // Bottom of the gene
    // const strand = gene.strand;
    
    return { points, points2, isBoxSelected, brokenGene };
}

export { CalculateGeneCoordinates };


function getBoundingBox(points) {
    let xmin = Infinity, xmax = -Infinity, ymin = Infinity, ymax = -Infinity;
    for (let i = 0; i < points.length; i++) {
        const [x, y] = points[i];
        xmin = Math.min(xmin, x);
        xmax = Math.max(xmax, x);
        ymin = Math.min(ymin, y);
        ymax = Math.max(ymax, y);
    }
    return { x: { min: xmin, max: xmax }, y: { min: ymin, max: ymax } };
}

function isOverlapping1D(interval1, interval2) {
    return interval1.min <= interval2.max && interval2.min <= interval1.max;
}

function isGeneOverlappingBox(points, boxCoords, genomeGroup, yScrollOffset) {
    const adjustedGenePoints = adjustGenePoints(points, genomeGroup.y, yScrollOffset); // wheel scroll offset not modifying points here :(
    const geneBox = getBoundingBox(adjustedGenePoints);
    const box = { x: { min: boxCoords.left, max: boxCoords.left + boxCoords.width },
                  y: { min: boxCoords.top, max: boxCoords.top + boxCoords.height } };
    return isOverlapping1D(geneBox.x, box.x) && isOverlapping1D(geneBox.y, box.y);
}

function adjustGenePoints(points, yOffset, yScrollOffset) {
    // Adjust the Y-coordinates of the polygon points based on genomeGroup.y and the scroll offset
    const adjustedPoints = [];
    for (let i = 0; i < points.length; i += 2) {
        // Apply both yOffset (genomeGroup specific) and yScrollOffset (global scroll)
        adjustedPoints.push([points[i], points[i + 1] + yOffset + yScrollOffset]);
    }
    return adjustedPoints;
}