import React, { useEffect } from 'react';
import '../style/dashboard.css'; 
import '../style/sidebar.css'; 
import { motion, useAnimate, AnimatePresence} from 'framer-motion';
import { useState } from "react";
import Viewer from './Viewer';
import { NotificationsProvider } from '../utilities/NotificationsContext';
import ControlPanel from '../components/ControlPanel';
import { useAuth0 } from "@auth0/auth0-react";
import DatabaseList from '../components/DatabaseList';
import DatabaseFiles from '../components/DatabaseFiles';     
import LeftSideHeader from '../components/LeftSideHeader';
import LeftSideSeachBar from '../components/LeftSideSearchBar';
import SlidingHandle from '../components/SlidingHandle';
import DatabaseSettingsPanel from '../components/DatabaseSettingsPanel';
import RightSideInfoPanel from '../components/RightSideInfoPanel';
import SearchBox from '../components/SearchBox';
import ShareBox from '../components/ShareBox';

const Dashboard = () => {
    const [genomeJSON, setGenomeJSON] = useState([]);
    const [nucJSON, setNucJSON] = useState(null);
    const [alnTSV, setAlnTSV] = useState(null);
    const [visibleGenomeJSON, setVisibleGenomeJSON] = useState(null);
    const [progress, setProgress] = useState(0); // Track progress
    const [scope, animate] = useAnimate()
    const [clickedViewerSettings, setClickedViewerSettings] = useState(false); // State to track dragging
    const [isUploading, setIsUploading] = useState(null);
    const [sequenceView, setSequenceView] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [viewerTop, setViewerTop] = useState('50vh');
    const [clusterData, setClusterData] = useState(null);
    const [pdbData, setPdbData] = useState('');
    const [selectedDatabase, setSelectedDatabase] = useState(null);
    const [userData, setUserData] = useState(null);
    const [searchInput, setSearchInput] = useState('');
    const [settingClicked, setSettingClicked] = useState(false);
    const [settingClickedLoc, setSettingClickedLoc] = useState({ display: 'none' });
    const [settingType, setSettingType] = useState(''); // ['edit', 'delete'
    const [focusDB, setFocusDB] = useState(null);
    const [databases, setDatabases] = useState([])
    const [files, setFiles] = useState([]);
    const [scrollY, setScrollY] = useState(0); // Added state for vertical scroll position
    const [scrollX, setScrollX] = useState(0); // Added state for vertical scroll position
    const [displacement, setDisplacement] = useState(0); // Added state for vertical scroll position
    const [infoPanelOpen, setInfoPanelOpen] = useState(false);
    const [infoPanelWidth, setInfoPanelWidth] = useState(300); // Initial width
    const [disableScroll, setDisableScroll] = useState(false); // New state for disabling scroll
    const [clusterGroupClickedInfo, setClusterGroupClickedInfo] = useState([]);
    const [proteinAlignment, setProteinAlignment] = useState(null);
    const [disableDrag, setDisableDrag] = useState(false); // State to track dragging
    const [clickedGeneFamily, setClickedGeneFamily] = useState(null);
    const [repCSSColor, setRepCSSColor] = useState(null);
    const [phylogenyData, setPhylogenyData] = useState(null);
    const [isDraggable, setIsDraggable] = useState(true); // State to control drag
    const [selectedGenes, setSelectedGenes] = useState([]);
    const [triggerDatabaseFilesRefresh, setTriggerDatabaseFilesRefresh] = useState(0);
    const [filesCache, setFilesCache] = useState({});
    const [sharedURL, setSharedURL] = useState(null);
    const [accountStatus, setAccountStatus] = useState(null);
    const [numberOfClusters, setNumberOfClusters] = useState(null);
    const [seachClicked, setSeachClicked] = useState(false);

    const { user } = useAuth0();
      // Update the width based on dragging
      const [extensionWidth, setExtensionWidth] = useState(200); // Initial width
      const [isExtensionVisible, setIsExtensionVisible] = useState(false);

      const startDrag = (e) => {
        const startX = e.clientX;
        const startWidth = extensionWidth;
      
        const doDrag = (dragEvent) => {
          const proposedNewWidth = startWidth + dragEvent.clientX - startX;
          
          if (proposedNewWidth >= 80) {
            // Update the width if the new width is greater than or equal to 80px
            setExtensionWidth(proposedNewWidth);
          } else if (proposedNewWidth < 20) {
            // If the width goes below 20px, trigger the animation to hide the component
            setIsExtensionVisible(false); // This will cause the component to animate out
          }
          // If proposedNewWidth is between 20px and 80px, do nothing
        };
      
        const stopDrag = () => {
          document.removeEventListener('mousemove', doDrag);
          document.removeEventListener('mouseup', stopDrag);
        };
      
        document.addEventListener('mousemove', doDrag);
        document.addEventListener('mouseup', stopDrag);
      };
      const infoPanelDrag = (e) => {
        const startX = e.clientX;
        const startWidth = infoPanelWidth;
        const startRightComponentWidth = document.getElementById('right-component').offsetWidth;
      
        const doDrag = (dragEvent) => {
            let newInfoPanelWidth = startWidth - (dragEvent.clientX - startX);
            let newRightComponentWidth = startRightComponentWidth + (dragEvent.clientX - startX);
          
            // Prevent newInfoPanelWidth from going below 200 pixels
            if (newInfoPanelWidth < 200) {
              newInfoPanelWidth = 200; // Set the minimum width to 200 pixels
              // Adjust newRightComponentWidth accordingly to maintain the overall width
              newRightComponentWidth = startRightComponentWidth + (startWidth - 200);
            }
          
            setInfoPanelWidth(newInfoPanelWidth);
            document.getElementById('right-component').style.width = `${newRightComponentWidth}px`;
          };
          
        const stopDrag = () => {
          document.removeEventListener('mousemove', doDrag);
          document.removeEventListener('mouseup', stopDrag);
        };
      
        document.addEventListener('mousemove', doDrag);
        document.addEventListener('mouseup', stopDrag);
      };
    const closeSettings = () => {
      setSettingClicked(false);
      setSeachClicked(false);
      setSharedURL(null);
    }

    return (
        <NotificationsProvider>
            <div ref={scope} className="dashboard-container">
            <AnimatePresence>
            {progress!==100 && (
                <motion.div className="progress" initial={{ opacity: 0, scaleX: 0}} animate={{ scaleX: progress/100, opacity: 1}} transition={{ duration: 0.222 }} exit={{ y: -10 }}/>
                )}
            </AnimatePresence>
            {(settingClicked || sharedURL || seachClicked) && (
            <>
                <div className="invisible-overlay" onClick={closeSettings} style={{pointerEvents: 'auto'}}></div> {/* The overlay with pointer-events auto to catch clicks */}
                {settingClickedLoc && (
                <div style={settingClickedLoc}>
                    <DatabaseSettingsPanel setSettingType={setSettingType} closeSettings={closeSettings}/>
                </div>
                )}
                {sharedURL && (
                  <ShareBox sharedURL={sharedURL}/>
                )}
                {seachClicked && (
                  <SearchBox visibleGenomeJSON={visibleGenomeJSON}/>
                  )}
            </>
            )}
            <div style={{display: "flex", flexDirection: "row"}}>
                <div id="left-component" className="left-component" >
                    <LeftSideHeader user={user} />
                    <LeftSideSeachBar 
                        searchInput={searchInput} 
                        setSearchInput={setSearchInput}
                    />
                    <DatabaseList 
                        setVisibleGenomeJSON={setVisibleGenomeJSON} 
                        setSelectedFiles={setSelectedFiles} 
                        selectedDatabase={selectedDatabase}
                        setSelectedDatabase={setSelectedDatabase}
                        userData={userData}
                        setUserData={setUserData}    
                        setIsExtensionVisible={setIsExtensionVisible}
                        setExtensionWidth={setExtensionWidth}
                        searchInput={searchInput}
                        setSettingClicked={setSettingClicked}
                        setSettingClickedLoc={setSettingClickedLoc}
                        settingClickedLoc={settingClickedLoc}
                        setSettingType={setSettingType}
                        settingType={settingType}
                        setFocusDB={setFocusDB}
                        focusDB={focusDB}
                        genomeJSON={genomeJSON}
                        databases={databases}
                        setDatabases={setDatabases}
                        triggerDatabaseFilesRefresh={triggerDatabaseFilesRefresh}
                        filesCache={filesCache}
                        setFilesCache={setFilesCache}
                        setAccountStatus={setAccountStatus}
                        accountStatus={accountStatus}
                    />
                </div>
                <AnimatePresence>
                {isExtensionVisible && (
                <motion.div
                    className="left-extension"
                    initial={{ opacity: 1, width: extensionWidth }}
                    exit={{ opacity: 0, width: 0, transition: { duration: 0.2 } }}
                    animate={{ opacity: 1, width: extensionWidth, transition: { duration: 0.0 } }}>
                    <div className='left-extension-files' style={{ width: extensionWidth }}>
                        <DatabaseFiles
                            userData={userData}
                            selectedDatabase={selectedDatabase}
                            setSelectedFiles={setSelectedFiles}
                            selectedFiles={selectedFiles}
                            setVisibleGenomeJSON={setVisibleGenomeJSON}
                            setClusterData={setClusterData}
                            setNucJSON={setNucJSON}
                            setSequenceView={setSequenceView}
                            searchInput={searchInput}
                            databases={databases}
                            setDatabases={setDatabases}
                            setFiles={setFiles}
                            files={files}
                            genomeJSON={genomeJSON}
                            setScrollX={setScrollX}
                            setScrollY={setScrollY}
                            setDisplacement={setDisplacement}
                            filesCache={filesCache}
                            setFilesCache={setFilesCache}
                        />
                    </div>
                    <SlidingHandle onDrag={startDrag} side="left"/>
                </motion.div>
                )}
                </AnimatePresence>
                <motion.div id="right-component" className="right-component">
                    <ControlPanel 
                        genomeJSON={genomeJSON}
                        visibleGenomeJSON={visibleGenomeJSON}
                        selectedFiles={selectedFiles}
                        sequenceView={sequenceView}
                        setSequenceView={setSequenceView}
                        setClickedViewerSettings={setClickedViewerSettings}
                        clusterData={clusterData}
                        setClusterData={setClusterData}
                        setNucJSON={setNucJSON}
                        nucJSON={nucJSON}
                        setAlnTSV={setAlnTSV}
                        setSelectedDatabase={setSelectedDatabase}
                        selectedDatabase={selectedDatabase}
                        setUserData={setUserData}
                        userData={userData}
                        setSharedURL={setSharedURL}
                        numberOfClusters={numberOfClusters}
                        setSeachClicked={setSeachClicked}
                    />
                    {/* <DragHandle dragHandleTop={dragHandleTop} setDragHandleTop={setDragHandleTop} viewerTop={viewerTop} setViewerTop={setViewerTop} /> */}
                    <Viewer // has a ref ref={viewerRef}  and className   className="viewer-container" 
                        viewerTop={viewerTop}
                        setViewerTop={setViewerTop}
                        setGenomeJSON={setGenomeJSON} 
                        setProgress={setProgress} 
                        clickedViewerSettings={clickedViewerSettings}
                        setClickedViewerSettings={setClickedViewerSettings}
                        setIsUploading={setIsUploading}
                        visibleGenomeJSON={visibleGenomeJSON}
                        setVisibleGenomeJSON={setVisibleGenomeJSON}
                        sequenceView={sequenceView}
                        setSequenceView={setSequenceView}
                        clusterData={clusterData}
                        setClusterData={setClusterData}
                        setNucJSON={setNucJSON}
                        nucJSON={nucJSON}
                        alnTSV={alnTSV}
                        setPdbData={setPdbData}
                        pdbData={pdbData}
                        focusDB={focusDB}
                        setSelectedDatabase={setSelectedDatabase}
                        selectedDatabase={selectedDatabase}
                        setScrollX={setScrollX}
                        setScrollY={setScrollY}
                        scrollX={scrollX}
                        scrollY={scrollY}
                        setSelectedFiles={setSelectedFiles}
                        setDisplacement={setDisplacement}
                        displacement={displacement}
                        infoPanelOpen={infoPanelOpen}
                        setInfoPanelOpen={setInfoPanelOpen}
                        setProteinAlignment={setProteinAlignment}
                        setClickedGeneFamily={setClickedGeneFamily}
                        clickedGeneFamily={clickedGeneFamily}
                        setDisableDrag={setDisableDrag}
                        disableDrag={disableDrag}
                        setDisableScroll={setDisableScroll}
                        setRepCSSColor={setRepCSSColor}
                        setClusterGroupClickedInfo={setClusterGroupClickedInfo}
                        clusterGroupClickedInfo={clusterGroupClickedInfo}
                        setIsDraggable={setIsDraggable}
                        isDraggable={isDraggable}
                        setSelectedGenes={setSelectedGenes}
                        selectedGenes={selectedGenes}
                        setTriggerDatabaseFilesRefresh={setTriggerDatabaseFilesRefresh}
                        filesCache={filesCache}
                        setFilesCache={setFilesCache}
                        setFiles={setFiles}
                        files={files}
                        setNumberOfClusters={setNumberOfClusters}
                    />
                </motion.div>
                {infoPanelOpen && (
                <>
                <div className="info-panel" style={{ width: infoPanelWidth }}>
                <SlidingHandle onDrag={infoPanelDrag} side="right" />
                    <RightSideInfoPanel
                    clusterGroupClickedInfo={clusterGroupClickedInfo}
                    proteinAlignment={proteinAlignment}
                    setDisableDrag={setDisableDrag}
                    setDisableScroll={setDisableScroll}
                    setClickedGeneFamily={setClickedGeneFamily}
                    repCSSColor={repCSSColor}
                    visibleGenomeJSON={visibleGenomeJSON}
                    disableDrag={disableDrag}
                    phylogenyData={phylogenyData}
                    setProteinAlignment={setProteinAlignment}
                    setPhylogenyData={setPhylogenyData}
                    setIsDraggable={setIsDraggable}
                    pdbData={pdbData}
                    setInfoPanelWidth={setInfoPanelWidth}
                    setInfoPanelOpen={setInfoPanelOpen}
                    selectedGenes={selectedGenes}
                    />
                </div>
                </>
                )}
            </div>

        </div>
        </NotificationsProvider>

    );
};

export default Dashboard;
