// Import necessary modules
import AWS from 'aws-sdk';

const s3 = new AWS.S3({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION,
});

export { s3 };

// The downloadGeneMaps function updated to include object.Key
// The downloadGeneMaps function updated to include object.Key within the JSON data
export const downloadGeneMaps = async (userSessionId, userSub) => {
  const s3Bucket = "phamlite-bucket";
  if (!userSessionId) return;

  const listParams = {
    Bucket: s3Bucket,
    Prefix: `genomes_json/${userSessionId}/`,
  };

  try {
    const listedObjects = await s3.listObjectsV2(listParams).promise();
    let combinedData = [];  // Initialize combinedData as an array

    for (const object of listedObjects.Contents) {
      // Skip the file if it is named genomes.json
      if (object.Key === `genomes_json/${userSessionId}/genomes.json`) continue;

      if (object.Key.endsWith('.json')) {  // Ensure the file is a JSON
        const downloadParams = {
          Bucket: s3Bucket,
          Key: object.Key,
        };

        const file = await s3.getObject(downloadParams).promise();
        // Parse the received file body to JSON
        const jsonData = JSON.parse(file.Body.toString());

        // Embed the S3 object key directly within the jsonData object
        jsonData['objectKey'] = object.Key;  // Add the object.Key as a property within jsonData

        combinedData.push(jsonData);  // Push the enhanced jsonData directly onto combinedData
      }
    }

    return combinedData;
  } catch (error) {
    console.error("Error downloading or listing files: ", error);
  }
};


export const downloadNucRibbons = async (userSessionId) => {
  const s3Bucket = "phamlite-bucket";
  if (!userSessionId) return;

  // List all JSON files in the directory
  const listParams = {
    Bucket: s3Bucket,
    Prefix: `blastn_json/${userSessionId}/`,
  };

  try {
    const listedObjects = await s3.listObjectsV2(listParams).promise();
    let combinedNucJSON = {};

    for (const object of listedObjects.Contents) {
      if (object.Key.endsWith('.json')) {
        // Previous single file download structure
        // const params = {
        //   Bucket: s3Bucket,
        //   Key: `blastn_json/${userSessionId}/blastn_results.json`,
        // };

        const downloadParams = {
          Bucket: s3Bucket,
          Key: object.Key,
        };

        const file = await s3.getObject(downloadParams).promise();
        const nucJSON = JSON.parse(file.Body.toString());

        // Assuming that we're just aggregating all JSON data into a single object
        combinedNucJSON = { ...combinedNucJSON, ...nucJSON };
      }
    }

    return { combinedNucJSON };
  } catch (error) {
    console.error("Error downloading files: ", error);
  }
};